import React, { useState } from 'react';
import "../../styles/personalDetails.scss";
import { Form } from 'react-bootstrap';
import Select from '../../components/select/Select';
import { EmpEmergencyDetails, HandleData } from '../../pages/PersonalDetails';
import { handleValidateMobileNumber } from '../../utils/properties';
interface PropsObject {
    empEmergencyDetails: EmpEmergencyDetails,
    handleData: HandleData
}

const EmergencyContactDetails = React.memo((props: PropsObject) => {
    const [indMobileNumbers, setIndMobileNumbers] = useState<string[]>(['6', '7', '8', '9']);
    const { empEmergencyDetails, handleData } = props

    let relationOptions = [
        {
            value: "Father",
            title: "Father",
        },
        {
            value: "Mother",
            title: "Mother",
        },
        {
            value: "Husband",
            title: "Husband",
        },
        {
            value: "Wife",
            title: "Wife",
        },
        {
            value: "Sister",
            title: "Sister",
        },
        {
            value: "Brother",
            title: "Brother",
        }
    ];

    const blockInvalidChar = (e: React.KeyboardEvent<HTMLElement>) => {
        if (!/^\d+$/.test(e.key) && e.key !== "Backspace" && e.key !== "Tab" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "v" && e.key !== "V") {
            e.preventDefault()
        }
        return true;
    };

    const handleOnChange = (stateName: string, value: string | Date | null) => {
        if (stateName === "contact_number") {
            if (!isNaN(Number(value))) {
                if (handleValidateMobileNumber(value as string)) {
                    handleData('empEmergencyDetails', stateName, value);
                }
                else {
                    return;
                }
            }
        }
        else {
            handleData('empEmergencyDetails', stateName, value);
        }
    };

    return (
        <div className='ect-details'>
            <h5>Emergency Contact Details</h5>
            <Form>
                <Form.Group className="int-grp gen-slt">
                    <Form.Label>How the contact is related to you? <span>*</span></Form.Label>
                    <Select
                        value={empEmergencyDetails?.relation_type}
                        options={relationOptions}
                        onChange={(val) => handleOnChange("relation_type", val)}
                        placeholder="Select one"
                        width="100%"
                        margin="0.75rem 0 0"
                        className={`${empEmergencyDetails?.relationError ? 'error-msg' : ''}`}
                    />
                    {empEmergencyDetails?.relationError && <Form.Text id="empMail">Plese select the relation</Form.Text>}
                </Form.Group>
                <Form.Group className="int-grp">
                    <Form.Label>Full name <span>*</span></Form.Label>
                    <Form.Control name="fullName" type="text" value={empEmergencyDetails?.name} className={`${empEmergencyDetails?.nameError ? 'error-msg' : ''}`}
                        onChange={(e) => handleOnChange("name", e.target.value)} />
                    {empEmergencyDetails?.nameError && <Form.Text id="empMail">Please enter the name</Form.Text>}
                </Form.Group>
                <Form.Group className="int-grp">
                    <Form.Label>Contact number <span>*</span></Form.Label>
                    <Form.Control name="contactNumber" className={`${empEmergencyDetails?.contactError ? 'error-msg' : ''}`} type="text" maxLength={10}
                        value={empEmergencyDetails?.contact_number} onKeyDown={(e) => blockInvalidChar(e)}
                        onChange={(e) => handleOnChange("contact_number", e.target.value)} />
                    {empEmergencyDetails?.contactError &&
                        <Form.Text id="empMail">
                            {empEmergencyDetails?.contact_number.trim() === "" ? "Please enter the contact number" : "Enter valid contact number"}
                        </Form.Text>
                    }
                </Form.Group>
            </Form>
        </div>
    );
});

export default EmergencyContactDetails;