import React from 'react'
import HeaderLayout from '../components/HeaderLayout'
import DocumentDetails from '../components/DocumentDetail/DocumentDetails'
import { CompanyProps } from '../App'

const DocumentDetail = (props: CompanyProps) => {
  return (


    <HeaderLayout companyLogo={props?.companyDetails?.logo}>
      <DocumentDetails />
    </HeaderLayout>
  )
}

export default DocumentDetail