import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import "../../styles/educationalDetails.scss";
import Select from '../select/Select';
import FileUpload from '../fileUpload/FileUpload';
import Calendar from '../datePicker/Calendar';
import moment from 'moment';
import { EducationDetails, HandleData } from '../../pages/EducationalDetails';
import { OptionObject } from '../../pages/PersonalDetails';

interface PropsObject {
    ind: number,
    eduDetails: EducationDetails,
    handleData: HandleData,
    passingYearOptions: OptionObject[],
    courseOptions: OptionObject[],
    gradeOptions: OptionObject[]
}

const EduDetails = (props: PropsObject) => {
    const { eduDetails, handleData, ind, passingYearOptions, courseOptions, gradeOptions } = props

    const handleFileChange = (file: File | null | string) => {
        if (typeof file !== "string") {
            handleData("mark_sheet", file, ind);
        }
        else {
            handleData("marksheetErrorValue", file, ind);
        }
    };

    const blockInvalidChar = (e: React.KeyboardEvent<HTMLElement>) => {
        if (!/^[0-9.]+$/.test(e.key) && e.key !== "Backspace") {
            e.preventDefault()
        }
        return true;
    };

    const handleOnChange: HandleData = (stateName, value, ind, subKey) => {
        if (eduDetails?.edu_system?.mark_system === "percentage" && subKey === "mark") {
            if (Number(value) <= 100) {
                handleData(stateName, value, ind, subKey);
            }
            else {
                return;
            }
        }
        else {
            handleData(stateName, value, ind, subKey);
        }
    }

    return (
        <div className='edu-data'>
            <Form>
                <Form.Group className={`int-grp ${eduDetails?.education !== "10th/SSLC" && eduDetails?.education !== "12th / HSC / Diploma" ? 'gen-slt' : ''}`}>
                    <Form.Label>Education <span>*</span></Form.Label>
                    {
                        eduDetails?.education !== "10th/SSLC" && eduDetails?.education !== "12th / HSC / Diploma" ?
                            <Select
                                value={eduDetails?.education}
                                options={courseOptions}
                                onChange={(val) => handleOnChange("education", val, ind, '')}
                                placeholder="Select one"
                                width="100%"
                                margin="0.75rem 0 0"
                                className={`${eduDetails?.educationError ? 'error-msg' : ''}`}
                            /> :
                            <Form.Control name="addressLineOne" type="text" value={eduDetails?.education} disabled />
                    }
                    {eduDetails?.educationError && <Form.Text id="empMail">Plese select the education</Form.Text>}
                </Form.Group>
                {eduDetails?.education !== "10th/SSLC" && eduDetails?.education !== "12th / HSC / Diploma" &&
                    <>
                        <Form.Group className="int-grp">
                            <Form.Label>Course <span>*</span></Form.Label>
                            <Form.Control name="Course" type="text" value={eduDetails?.course_name ? eduDetails?.course_name : ""}
                                onChange={(e) => handleOnChange("course_name", e.target.value, ind, '')}
                                className={`${eduDetails?.courseNameError ? 'error-msg' : ''}`}
                            />
                            {eduDetails?.courseNameError && <Form.Text id="empMail">Plese enter the course name</Form.Text>}
                        </Form.Group>
                        <Form.Group className="int-grp gen-slt">
                            <Form.Label>Course type <span>*</span></Form.Label>
                            <div className='radio-input'>
                                <Form.Check
                                    label="Full time"
                                    name={`${ind} ${eduDetails?.education} courseType`}
                                    type="radio"
                                    id={`${ind} ${eduDetails?.education} fullTime-btn`}
                                    className='active'
                                    checked={eduDetails?.course_type === 'full time'}
                                    onChange={(e) => e.target.checked && handleOnChange("course_type", "full time", ind, '')}
                                />
                                <Form.Check
                                    label="Part time"
                                    name={`${ind} ${eduDetails?.education} courseType`}
                                    type="radio"
                                    id={`${ind} ${eduDetails?.education} partTime-btn`}
                                    className='ms-5'
                                    checked={eduDetails?.course_type === 'part time'}
                                    onChange={(e) => e.target.checked && handleOnChange("course_type", "part time", ind, '')}
                                />
                                <Form.Check
                                    label="Correspondence / Distance learning"
                                    name={`${ind} ${eduDetails?.education} courseType`}
                                    type="radio"
                                    id={`${ind} ${eduDetails?.education} distance-btn`}
                                    className='ms-5'
                                    checked={eduDetails?.course_type === 'correspondence / distance learning'}
                                    onChange={(e) => e.target.checked && handleOnChange("course_type", "correspondence / distance learning", ind, '')}
                                />
                            </div>
                        </Form.Group>
                    </>
                }
                <Form.Group className="int-grp">
                    <Form.Label>{eduDetails?.education !== "10th/SSLC" ? "School/University name" : "School name"} <span>*</span></Form.Label>
                    <Form.Control name="schoolName" type="text" value={eduDetails?.school_name} className={`${eduDetails?.schoolNameError ? 'error-msg' : ''}`}
                        onChange={(e) => handleOnChange("school_name", e.target.value, ind, '')} />
                    {eduDetails?.schoolNameError && <Form.Text id="empMail">Plese enter the school name</Form.Text>}
                </Form.Group>
                {eduDetails?.education !== "10th/SSLC" && eduDetails?.education !== "12th / HSC / Diploma" ?
                    <div className='d-flex justify-content-between flex-fill'>
                        <div className='w-100'>
                            <Calendar
                                selectedDate={eduDetails?.joining_year}
                                dateUpdate={(date) => handleOnChange("joining_year", date, ind, '')}
                                dpType={'month'}
                                label={'From'}
                                isError={eduDetails?.fromDateError && true}
                                errorValue={eduDetails?.fromDateErrorValue}
                                isMandatory={true}
                                minDate={new Date("1970-01-01")}
                                maxDate={eduDetails?.passing_year ? eduDetails?.passing_year : new Date()}
                            // isDisable={false}
                            />
                        </div>
                        <div className='w-100 ms-3'>
                            <Calendar
                                selectedDate={eduDetails?.passing_year}
                                dateUpdate={(date) => handleOnChange("passing_year", date, ind, '')}
                                dpType={'month'}
                                label={'To'}
                                isError={eduDetails?.toDateError && true}
                                errorValue={eduDetails?.toDateErrorValue}
                                isMandatory={true}
                                minDate={eduDetails?.joining_year ? eduDetails?.joining_year : new Date("1970-01-01")}
                                maxDate={new Date()}
                            // isDisable={false}
                            />
                        </div>
                    </div> :
                    <Form.Group className="int-grp gen-slt">
                        <Form.Label>Passing out year <span>*</span></Form.Label>
                        <Select
                            value={eduDetails?.passed_out}
                            options={passingYearOptions}
                            onChange={(val) => handleOnChange("passed_out", val, ind, '')}
                            placeholder="Select one"
                            width="100%"
                            margin="0.75rem 0 0"
                            className={`${eduDetails?.passedOutError ? 'error-msg' : ''}`}
                        />
                        {eduDetails?.passedOutError && <Form.Text id="empMail">Plese select the passing out year</Form.Text>}
                    </Form.Group>
                }
                <Form.Group className="int-grp gen-slt">
                    <Form.Label>
                        {eduDetails?.education !== "10th/SSLC" && eduDetails?.education !== "12th / HSC / Diploma" ? "Grading system" : "Percentage/Grade"} <span>*</span>
                    </Form.Label>
                    <div className='radio-input'>
                        <Form.Check
                            label="Grade"
                            name={`${ind} ${eduDetails?.education} markSystem`}
                            type="radio"
                            id={`${ind} ${eduDetails?.education} grade-btn`}
                            className='active'
                            checked={eduDetails?.edu_system?.mark_system === "grade"}
                            onChange={(e) => e.target.checked && handleOnChange("edu_system", "grade", ind, 'mark_system')}
                        />
                        <Form.Check
                            label="Percentage"
                            name={`${ind} ${eduDetails?.education} markSystem`}
                            type="radio"
                            id={`${ind} ${eduDetails?.education} percentage-btn`}
                            className='ms-5'
                            checked={eduDetails?.edu_system?.mark_system === "percentage"}
                            onChange={(e) => e.target.checked && handleOnChange("edu_system", "percentage", ind, 'mark_system')}
                        />
                    </div>
                    {eduDetails?.edu_system?.mark_system === "grade" ?
                        <Select
                            value={eduDetails?.edu_system?.mark}
                            options={gradeOptions}
                            onChange={(val) => handleOnChange("edu_system", val, ind, 'mark')}
                            placeholder="Select one"
                            width="100%"
                            margin="0.75rem 0 0"
                            className={`${eduDetails?.markError ? 'error-msg' : ''}`}
                        /> :
                        <Form.Control name="percentage" type="text" value={eduDetails?.edu_system?.mark} className={`${eduDetails?.markError ? 'error-msg' : ''}`}
                            onChange={(e) => handleOnChange("edu_system", e.target.value, ind, 'mark')} onKeyDown={(e) => blockInvalidChar(e)}
                            maxLength={parseInt(eduDetails?.edu_system?.mark) < 10 ? 4 : 5} />
                    }
                    {eduDetails?.markError && <Form.Text id="empMail">{eduDetails?.edu_system?.mark_system === "grade" ? "Plese select the grade" : "Please enter the percentage"}</Form.Text>}
                </Form.Group>
                <Form.Group className="int-grp">
                    <Form.Label>Marksheet <span>*</span></Form.Label>
                    <FileUpload fileValue={eduDetails?.mark_sheet} fileName={eduDetails?.mark_sheet_name} callBack={handleFileChange} isDisable={false}
                        customClass={`${eduDetails?.marksheetError ? 'error-msg' : ''}`}
                    />
                    {eduDetails?.marksheetError && <Form.Text id="empMail">
                        {eduDetails?.marksheetErrorValue === "" ? "Please upload the marksheet" : eduDetails?.marksheetErrorValue}
                    </Form.Text>}
                </Form.Group>
            </Form>
        </div>
    );
};

export default EduDetails;