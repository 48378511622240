import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router';


interface PropsObject {
    "handleSave": () => void,
    "isDisable"?: boolean
}

const FooterLayout = (props: PropsObject) => {
    const path = window.location.pathname.split('/')
    const Navigate = useNavigate();

    return (
        < div className='footer d-flex justify-content-between' >
            {
                path[1] !== 'personal' && <Button variant='outline-dark' className='back-btn' onClick={() => {
                    Navigate(path[1] === 'education' ? '/personal' : path[1] === 'professional' ? '/education' : path[1] === 'document' ? '/professional' : '/document')
                }}>
                    Back
                </Button>
            }
            < Button variant='dark' className={`save-btn ${path[1] === 'personal' ? 'ms-auto' : ''}`
            } onClick={() => props?.handleSave()} disabled={props?.isDisable}>
                {path[1] === 'declaration' ? 'Submit' : 'Save & Continue'}
            </Button >
        </div >
    );
};

export default FooterLayout;