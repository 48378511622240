import React, { useState } from 'react';
import "../../styles/personalDetails.scss";
import { Form, Image } from 'react-bootstrap';
import Select from '../../components/select/Select';
import Calendar from '../datePicker/Calendar';
import { EmpBasicDetailsProps, HandleData } from '../../pages/PersonalDetails';
import { handleValidateMobileNumber } from '../../utils/properties';


interface PropsObject {
    empBasicDetails: EmpBasicDetailsProps,
    handleData: HandleData
}

const EmpBasicDetails = React.memo((props: PropsObject) => {
    const [indMobileNumbers, setIndMobileNumbers] = useState<string[]>(['6', '7', '8', '9']);
    const { empBasicDetails, handleData } = props

    let genderOptions = [
        {
            value: "Male",
            title: "Male",
        },
        {
            value: "Female",
            title: "Female",
        },
        {
            value: "Others",
            title: "Others",
        }
    ];

    let maritalOptions = [
        {
            value: "Single",
            title: "Single",
        },
        {
            value: "Married",
            title: "Married",
        }
    ];

    let bloodGroupOptions = [
        {
            value: "A+ve",
            title: "A+",
        },
        {
            value: "A-ve",
            title: "A-",
        },
        {
            value: "B+ve",
            title: "B+",
        },
        {
            value: "B-ve",
            title: "B-",
        },
        {
            value: "AB+ve",
            title: "AB+",
        },
        {
            value: "AB-ve",
            title: "AB-",
        },
        {
            value: "O+ve",
            title: "O+",
        },
        {
            value: "O-ve",
            title: "O-",
        }
    ];

    const blockInvalidChar = (e: React.KeyboardEvent<HTMLElement>) => {
        if (!/^\d+$/.test(e.key) && e.key !== "Backspace" && e.key !== "Tab" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "v" && e.key !== "V") {
            e.preventDefault()
        }
        return true;
    };

    const handleOnChange = (stateName: string, value: string | Date | null) => {
        if (stateName === "contact_number") {
            if (!isNaN(Number(value))) {
                if (handleValidateMobileNumber(value as string)) {
                    handleData('empBasicDetails', stateName, value);
                }
                else {
                    return;
                }
            }
        }
        else {
            handleData('empBasicDetails', stateName, value);
        }
    };

    return (
        <div className='name-details'>
            <Form>
                <Form.Group className="int-grp">
                    <Form.Label>Name <span>*</span></Form.Label>
                    <Form.Control name="empName" type="text" value={empBasicDetails?.name} disabled />
                </Form.Group>
                <Calendar
                    selectedDate={empBasicDetails?.date_of_birth}
                    dateUpdate={(date) => handleOnChange("date_of_birth", date)}
                    dpType={'date'}
                    label={'Date of birth'}
                    isError={empBasicDetails?.dobError && true}
                    // minDate={new Date("1970-01-01")}
                    maxDate={new Date()}
                    // isDisable={false}
                    isMandatory={true}
                />
                <Form.Group className="int-grp gen-slt">
                    <Form.Label>Gender <span>*</span></Form.Label>
                    <Select
                        value={empBasicDetails?.gender}
                        options={genderOptions}
                        onChange={(val) => handleOnChange("gender", val)}
                        placeholder="Select one"
                        width="100%"
                        margin="0.75rem 0 0"
                        className={`${empBasicDetails?.genderError ? 'error-msg' : ''}`}
                    />
                    {empBasicDetails?.genderError && <Form.Text id="empMail">Plese select the gender</Form.Text>}
                </Form.Group>
                <Form.Group className="int-grp">
                    <Form.Label>Personal Email ID <span>*</span></Form.Label>
                    <Form.Control name="emailId" type="email" value={empBasicDetails?.personal_email_id} id='empMail' disabled />
                </Form.Group>
                <Form.Group className="int-grp">
                    <Form.Label>Contact number <span>*</span></Form.Label>
                    <Form.Control name="contactNumber" className={`${empBasicDetails?.contactError ? 'error-msg' : ''}`} type="text" maxLength={10}
                        value={empBasicDetails?.contact_number} onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => blockInvalidChar(e)}
                        onChange={(e) => handleOnChange("contact_number", e.target.value)} />
                    {empBasicDetails?.contactError &&
                        <Form.Text id="empMail">
                            {empBasicDetails?.contact_number.trim() === "" ? "Please enter the contact number" : "Enter valid contact number"}
                        </Form.Text>
                    }
                </Form.Group>
                <Form.Group className="int-grp gen-slt">
                    <Form.Label>Marital status <span>*</span></Form.Label>
                    <Select
                        value={empBasicDetails?.marital_status}
                        options={maritalOptions}
                        onChange={(val) => handleOnChange("marital_status", val)}
                        placeholder="Select one"
                        width="100%"
                        margin="0.75rem 0 0"
                        className={`${empBasicDetails?.maritalError ? 'error-msg' : ''}`}
                    />
                    {empBasicDetails?.maritalError && <Form.Text id="empMail">Plese select the marital status</Form.Text>}
                </Form.Group>
                <Form.Group className="int-grp gen-slt">
                    <Form.Label>Blood group <span>*</span></Form.Label>
                    <Select
                        value={empBasicDetails?.blood_group}
                        options={bloodGroupOptions}
                        onChange={(val) => handleOnChange("blood_group", val)}
                        placeholder="Select one"
                        width="100%"
                        margin="0.75rem 0 0"
                        className={`${empBasicDetails?.bloodError ? 'error-msg' : ''}`}
                    />
                    {empBasicDetails?.bloodError && <Form.Text id="empMail">Plese select the blood group</Form.Text>}
                </Form.Group>
            </Form>
        </div>
    );
});

export default EmpBasicDetails;