import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import "./permissionModal.scss";
import { ModalObject } from '../../pages/Declaration';

interface PropsObject {
    "modalData": ModalObject,
    "callBack": (val: string) => void
}

const PermissionModal = (props: PropsObject) => {

    return (
        <Modal
            show={props?.modalData?.modalShow}
            dialogClassName="permission-modal"
            backdrop="static"
            keyboard={false}
            // centered
        >
            <Modal.Body>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <p>Do you want to save changes before leaving this page?</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='outline-dark' className='yes-btn' onClick={() => props?.callBack('save')}>Save</Button>
                <Button variant='dark' className='no-btn' onClick={() => props?.callBack('discard')}>Discard</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PermissionModal;