export const Fields = ({ label, values }: { label: string, values: string }) => {
    return (
        <>
            <p className="card-label">{label === "Personal email id" ? "Personal Email Id" : label === "Passing year" ? "Passing out year" : label}</p>
            {label && <p className="card-value">{values}</p>}
        </>
    );
};

// Function to format labels (replace _ with space and capitalize only the first word)
export const formatLabel = (label: string): string => {
    return label
        .replace(/_/g, ' ') // Replace underscores with spaces
        .replace(/^\w/, (char) => char.toUpperCase()); // Capitalize the first letter of the first word only
};

// Rows will display two fields in a row
export const Rows = ({ label1, values1, label2, values2 }: { label1: string, values1: string, label2: string, values2: string }) => {
    return (
        <div className="d-flex justify-content-between  flex-row">
            <div className="w-50 ellipsis">
                {!label1.includes("File") && <Fields label={label1} values={values1 ? values1 : "---"} />}
            </div>
            <div className="w-50 ellipsis">
                {!label2.includes("File") && <Fields label={label2} values={values2 ? values2 : "---"} />}
            </div>
        </div>
    );
};

export interface BasicDetails {
    name: string,
    date_of_birth: string,
    gender: string,
    personal_email_id: string,
    contact_number: string,
    marital_status: string,
    blood_group: string,
}

export interface EmergencyDetails {
    relationship: string,
    Full_name: string,
    contact_number: string,
}

export interface Address {
    current_address: string,
    permanent_address: string,
    current_address_proof_name?: string
    permanent_address_proof_name?: string

}