import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface InitialState {
    "userMail": string,
    "otpSent": boolean
}

const initialState: InitialState = {
    userMail: "",
    otpSent: false
}

const loginSlice = createSlice({
    name: 'loginData',
    initialState,
    reducers: {
        setUserMail: (state, action: PayloadAction<string>) => {
            state.userMail = action.payload;
        },
        setOtpSent: (state, action: PayloadAction<boolean>) => {
            state.otpSent = action.payload;
        }
    }
})

export const { setUserMail, setOtpSent } = loginSlice.actions
export default loginSlice.reducer