import React from 'react'
// import { SectionTitle } from '../../pages/DocumentDetail'
import { Rows, formatLabel } from '../PersonalDetailDeclaration/utilities';
import { ExperienceDetail, FileRows, SectionGeneratorforhr } from './utilities';
import { SectionTitle } from '../../DocumentDetail/DocumentDetails';
import { HrDetail, WorkExperience } from '../../../pages/Declaration';
import { useNavigate } from 'react-router-dom';
interface ProfessionalDeclarationProps {
    workExperiences: WorkExperience[];
    hrDetails: HrDetail;
    totalExperience: string
    relevantExperience: string
    fresher?: boolean
}

const ProfessionalDeclaration: React.FC<ProfessionalDeclarationProps> = ({ workExperiences, hrDetails, totalExperience, relevantExperience, fresher }) => {
    const Navigate = useNavigate();


    const SectionGenerator = (basicDetails: ExperienceDetail) => {

        const fieldsArray = Object.entries(basicDetails);
        const rows = [];
        const rows1 = [];
        for (let i = 0; i < fieldsArray.length; i += 2) {
            const firstField = fieldsArray[i];
            let secondField = fieldsArray[i + 1] || ["", ""]; // Handle odd fields

            // Check if fields should go into rows1 or rows
            const isFirstFile = ["appointment_letter", "appraisal_letter", "releiving_letter", "bank_statement", "pay_slip"].includes(firstField[0]);
            const isSecondFile = ["appointment_letter", "appraisal_letter", "releiving_letter", "bank_statement", "pay_slip"].includes(secondField[0]);

            if (!isFirstFile && !isSecondFile) {
                // Neither field is a file, add to rows
                rows.push({
                    label1: formatLabel(firstField[0]),
                    value1: firstField[1],
                    label2: formatLabel(secondField[0]),
                    value2: secondField[1]
                });
            } else if (!isFirstFile && isSecondFile) {
                rows.push({
                    label1: formatLabel(firstField[0]),
                    value1: firstField[1],
                    label2: formatLabel(""),
                    value2: ""
                });
                rows1.push({
                    label1: formatLabel(secondField[0]),
                    value1: secondField[1],
                    label2: formatLabel(fieldsArray[i + 2][0]),
                    value2: fieldsArray[i + 2][1]
                });
                i += 1; // Skip the next field since it's now used as secondField

            } else {

                rows1.push({
                    label1: isFirstFile ? formatLabel(firstField[0]) : "",
                    value1: isFirstFile ? firstField[1] : "",
                    label2: isSecondFile ? formatLabel(secondField[0]) : "",
                    value2: isSecondFile ? secondField[1] : ""
                });
            }
        }

        return (
            <>
                {rows.map((row, index) => (
                    <Rows
                        key={index}
                        label1={row.label1}
                        values1={row.value1}
                        label2={row.label2}
                        values2={row.value2}
                    />
                ))}

                {rows1.map((row, index) => (
                    <FileRows
                        key={index}
                        label1={row.label1}
                        values1={row.value1}
                        label2={row.label2}
                        values2={row.value2}
                    />
                ))}
            </>
        );
    };


    return (
        <>
            <div className="card">
                <div className="d-flex justify-content-between align-items-center flex-row">
                    <SectionTitle title="Professional Details" />
                    <button className="edit-button" onClick={() => Navigate("/professional")}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M8.89116 2.41955C9.67162 1.63791 10.9375 1.63743 11.7185 2.41848L13.3798 4.07972C14.1541 4.8541 14.1622 6.10775 13.3978 6.89202L7.24236 13.2075C6.74065 13.7223 6.05254 14.0125 5.33401 14.0125L3.49925 14.0124C2.55166 14.0124 1.7949 13.2225 1.83476 12.275L1.91353 10.4023C1.94161 9.73456 2.21915 9.10167 2.69121 8.6289L8.89116 2.41955ZM10.7764 3.362C10.5161 3.10165 10.0941 3.10181 9.83397 3.36236L8.84329 4.35454L11.4593 6.97059L12.4437 5.96067C12.6985 5.69925 12.6958 5.28137 12.4377 5.02324L10.7764 3.362ZM3.63402 9.57171L7.9012 5.29806L10.5286 7.9255L6.28824 12.2762C6.03739 12.5336 5.69333 12.6787 5.33407 12.6787L3.4993 12.6786C3.30979 12.6786 3.15844 12.5206 3.16641 12.3311L3.24518 10.4584C3.25922 10.1245 3.39799 9.8081 3.63402 9.57171ZM13.6758 13.9637C14.0438 13.9637 14.3422 13.6651 14.3422 13.2968C14.3422 12.9285 14.0438 12.6299 13.6758 12.6299H9.59462C9.22656 12.6299 8.9282 12.9285 8.9282 13.2968C8.9282 13.6651 9.22656 13.9637 9.59462 13.9637H13.6758Z"
                                fill="#6B6B77"
                            />
                        </svg>
                        Edit
                    </button>
                </div>
                {!fresher ? (<><Rows
                    // key={index}
                    label1={"Total Experience"}
                    values1={totalExperience}
                    label2={"Relevant Experience"}
                    values2={relevantExperience}
                />

                    {workExperiences.map((exp, index) => {
                        return (
                            <>
                                <hr></hr>

                                <h5>Work Experience - {index + 1}</h5>
                                {SectionGenerator(exp)}
                            </>
                        )
                    })}
                    <hr></hr>
                    <h5>HR Contact Details</h5>
                    {(SectionGeneratorforhr(hrDetails))}</>) : (<>
                        <p className="card-label">{"Fresher"}</p>
                        <p className="card-value">{"yes"}</p>
                    </>)}
            </div>
        </>
    )
}

export default ProfessionalDeclaration