import axios, { AxiosInstance } from "axios";

// export const baseURL = 'https://dev.mytimesheet.in/api/'; //for dev
// export const baseURL = 'http://192.168.212.33:8000/api/'
export const baseURL = `${process.env.REACT_APP_API_URL}/api/`; //using env file
export const fileBaseURL = process.env.REACT_APP_API_URL


export async function commonGetService(url: string) {
    return axios
        .get(baseURL + url, { headers: { 'Authorization': `Bearer ${getCookie()}` } })
        .then((response) => {
            if (response.data.status === 401) {
                document.cookie =
                    "X-XSRF-TOKEN = null;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = "/";
            } else {
                return response.data;
            }
        })
        .catch(function (error) {
            if (error.response && error.response.status === 401) {
                return {
                    data: null,
                    message: "Unauthorized",
                    status: 401,
                };
            }
        });
}

export async function commonPostservice(url: string, data: unknown) {
    if (getCookie()) {
        return axios
            .post(baseURL + url, data, { headers: { 'Authorization': `Bearer ${getCookie()}` } })
            .then((response) => {
                if (response.data.status === 401) {
                    document.cookie =
                        "X-XSRF-TOKEN = null;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.href = "/";
                } else {
                    return response.data;
                }
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    return {
                        data: null,
                        message: "Unauthorized",
                        status: 401,
                    };
                }
            });
    } else {
        return axios
            .post(baseURL + url, data)
            .then((response) => {
                return response.data;
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    return {
                        data: null,
                        message: "Unauthorized",
                        status: 401,
                    };
                }
            });
    }
}

export const getCookie = () => {
    try {
        const cookieString = document.cookie || "";
        let lastToken;
        for (const cookie of cookieString.split(";")) {
            const cookieArray = cookie.split("=");
            if (cookieArray[0].trim() === "X-XSRF-TOKEN") {
                lastToken = cookieArray[1].trim();
                return lastToken;
            }
        }
    } catch {
        return;
    }
};

export const handleValidateEmail = (email: string) => {
    // const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
    const emailRegex = /^[a-zA-Z0-9](\.?[a-zA-Z0-9_-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;

    return !emailRegex.test(email.trim());
}

export const handleValidateMobileNumber = (updNumber: string) => {
    const indMobileNumbers: string[] = ['6', '7', '8', '9']
    if (
        (updNumber === "" || indMobileNumbers?.some(item => { return updNumber && (updNumber as string).startsWith(item) }))
    ) {
        return true;
    }
    else {
        return false;
    }
}