import React from 'react'
import { FileBadge, SectionGeneratorforDocs } from './utilities'
import { Image } from 'react-bootstrap'
import { SectionTitle } from '../../DocumentDetail/DocumentDetails'
import { DocumentDetail } from '../../../pages/Declaration'
import { useNavigate } from 'react-router-dom'

type DocumentDeclarationProps = {
    details: DocumentDetail;
};
const DocumentDeclaration: React.FC<DocumentDeclarationProps> = ({ details, ...rest }) => {
    const Navigate = useNavigate();


    return (
        <div className="card">
            <div className="d-flex justify-content-between align-items-center flex-row">
                <SectionTitle title="Document Details" />
                <button className="edit-button" onClick={() => Navigate("/document")}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8.89116 2.41955C9.67162 1.63791 10.9375 1.63743 11.7185 2.41848L13.3798 4.07972C14.1541 4.8541 14.1622 6.10775 13.3978 6.89202L7.24236 13.2075C6.74065 13.7223 6.05254 14.0125 5.33401 14.0125L3.49925 14.0124C2.55166 14.0124 1.7949 13.2225 1.83476 12.275L1.91353 10.4023C1.94161 9.73456 2.21915 9.10167 2.69121 8.6289L8.89116 2.41955ZM10.7764 3.362C10.5161 3.10165 10.0941 3.10181 9.83397 3.36236L8.84329 4.35454L11.4593 6.97059L12.4437 5.96067C12.6985 5.69925 12.6958 5.28137 12.4377 5.02324L10.7764 3.362ZM3.63402 9.57171L7.9012 5.29806L10.5286 7.9255L6.28824 12.2762C6.03739 12.5336 5.69333 12.6787 5.33407 12.6787L3.4993 12.6786C3.30979 12.6786 3.15844 12.5206 3.16641 12.3311L3.24518 10.4584C3.25922 10.1245 3.39799 9.8081 3.63402 9.57171ZM13.6758 13.9637C14.0438 13.9637 14.3422 13.6651 14.3422 13.2968C14.3422 12.9285 14.0438 12.6299 13.6758 12.6299H9.59462C9.22656 12.6299 8.9282 12.9285 8.9282 13.2968C8.9282 13.6651 9.22656 13.9637 9.59462 13.9637H13.6758Z"
                            fill="#6B6B77"
                        />
                    </svg>
                    Edit
                </button>
            </div>
            <hr></hr>
            <h5>Aadhar Details</h5>
            {SectionGeneratorforDocs(details?.aadharDetails)}
            <FileBadge fileName={details?.aadharDetails.aadharFileName ?? ''} />
            <hr></hr>
            <h5>Pan Details</h5>
            {SectionGeneratorforDocs(details?.panDetails)}
            <FileBadge fileName={details?.panDetails.panFileName ?? ""} />
            <hr></hr>
            <h5>Passport Details (Optional)</h5>
            {SectionGeneratorforDocs(details?.passportDetails)}
            <FileBadge fileName={details?.passportDetails.passportFileName ?? ''} />
            <hr></hr>
            <h5>Primary Bank Details </h5>
            {SectionGeneratorforDocs(details?.bankDetails?.primaryAccount)}
            <FileBadge fileName={details?.bankDetails?.primaryAccount.accountFileName ?? ''} />
            <hr></hr>
            <h5>Secondary Bank Details </h5>
            {SectionGeneratorforDocs(details?.bankDetails?.secondaryAccount)}
            <FileBadge fileName={details?.bankDetails?.secondaryAccount.accountFileName ?? ''} />



            <hr></hr>
            <h5>UAN Details (Optional)</h5>
            {SectionGeneratorforDocs(details?.uan)}


            <hr></hr>
            <h5>ESI Details (Optional)</h5>
            {SectionGeneratorforDocs(details?.esi)}

            {details?.dependentDetails.length > 0 && details?.dependentDetails.map((details) =>
                <>
                    <hr></hr>
                    <h5>Dependent Details (Optional)</h5>
                    {SectionGeneratorforDocs(details)}
                </>
            )
            }
        </div>
    )
}

export default DocumentDeclaration