import React from 'react';
import { Modal, Image, Button } from 'react-bootstrap';
import { commonPostservice } from '../../utils/properties';
import { useNavigate } from 'react-router';
import "./logoutModal.scss";

interface PropsObject {
    "showModal": boolean,
    "callBack": () => void
}

const LogoutModal = (props: PropsObject) => {

    const Navigate = useNavigate();

    const handleLogout = () => {
        const userDetailsString = sessionStorage.getItem('userDetails');
        if (userDetailsString) {
            const userDetails = JSON.parse(userDetailsString);
            let params = {
                email: userDetails?.userMail,
                otp: userDetails?.otp
            }

            commonPostservice(`onboard/logout`, params).then(res => {
                if (res.status) {
                    document.cookie = "X-XSRF-TOKEN = null;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    localStorage.clear();
                    sessionStorage.clear();
                    Navigate('/');
                }
            })
        }
    }


    return (
        <Modal
            show={props?.showModal}
            dialogClassName="logout-modal"
            backdrop="static"
            keyboard={false}
        >
            <Modal.Body>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <Image src='/images/components/logout_icon.svg' alt='logout' className='logout-icon' />
                    <h4>Are you sure you want to log out?</h4>
                    <p>Almost there! Logging out now might pause your progress.<br /> Do you want to continue?</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='outline-dark' className='yes-btn' onClick={() => handleLogout()}>Yes, Log Out</Button>
                <Button variant='dark' className='no-btn' onClick={() => props?.callBack()}>No, Stay Logged In</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default LogoutModal;