import React, { useEffect } from 'react';
import { Modal, Image, Button } from 'react-bootstrap';
import './submitModal.scss';
import { CompanyProps } from '../../App';
import HeaderLayout from '../HeaderLayout';
import { commonPostservice } from '../../utils/properties';
import { useNavigate } from 'react-router';


// interface PropsObject {
//     "showModal": boolean,
//     "callBack": () => void
// }

const SubmitModal = (props: CompanyProps) => {

    const Navigate = useNavigate();

    useEffect(() => {
        const logOut = setTimeout(() => {
            const userDetailsString = sessionStorage.getItem('userDetails');
            if (userDetailsString) {
                const userDetails = JSON.parse(userDetailsString);
                let params = {
                    email: userDetails?.userMail,
                    otp: userDetails?.otp
                }

                commonPostservice(`onboard/logout`, params).then(res => {
                    if (res.status) {
                        document.cookie = "X-XSRF-TOKEN = null;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                        localStorage.clear();
                        sessionStorage.clear();
                        Navigate('/');
                    }
                })
            }
        }, 5000)

        return () => {
            clearTimeout(logOut);
        }
    }, [])

    return (
        <HeaderLayout companyLogo={props?.companyDetails?.logo}>
            <Modal
                // show={props?.showModal}
                show={true}
                dialogClassName="submit-modal"
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <Image src='/images/components/tick_icon.svg' alt='Tick' className='tick-img' />
                        <h4>Submitted Successfully</h4>
                        <p>Thank you for submitting your details and documents.<br /> We're processing it.</p>
                        <h6>{props?.companyDetails?.name}</h6>
                    </div>
                </Modal.Body>
            </Modal>
        </HeaderLayout>
    );
};

export default SubmitModal;