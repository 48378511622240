import React, { useEffect, useRef, useState } from 'react';
import { Image } from 'react-bootstrap';
import "./taskProgressBar.scss";


interface PropsObject {
    "empName": string
}

const TaskProgressBar = (props: PropsObject) => {
    const [userName, setUserName] = useState<string>("");
    const path = window.location.pathname.split('/')
    const eduRef = useRef<HTMLImageElement>(null);
    const proRef = useRef<HTMLImageElement>(null);
    const docRef = useRef<HTMLImageElement>(null);
    const dlrRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        setTimeout(() => {
            if (path[1] === 'education') {
                if (eduRef.current) {
                    eduRef.current.src = '/images/task_progress_bar/active_dot.svg';
                }
            }
            else if (path[1] === 'professional') {
                if (proRef.current) {
                    proRef.current.src = '/images/task_progress_bar/active_dot.svg';
                }
            }
            else if (path[1] === 'document') {
                if (docRef.current) {
                    docRef.current.src = '/images/task_progress_bar/active_dot.svg';
                }
            }
            else if (path[1] === 'declaration') {
                if (dlrRef.current) {
                    dlrRef.current.src = '/images/task_progress_bar/active_dot.svg';
                }
            }
        }, 2650)
        getProfileInfo();
    })

    const getProfileInfo = () => {
        const userDetailsString = sessionStorage.getItem('userDetails');
        if (userDetailsString) {
            const userDetails = JSON.parse(userDetailsString);
            setUserName(userDetails?.name);
        }
    }

    return (
        <div className='bar-container'>
            <div className='welcome-msg'>
                <h4>Welcome Aboard, {userName}</h4>
            </div>
            <div className='d-flex task-bar'>
                <div className='d-flex flex-column align-items-center task-box flex-fill'>
                    <Image id='personal'
                        src={`/images/task_progress_bar/${path[1] === 'personal' ? 'active_dot' : 'completed_dot'}.svg`}
                    />
                    <h6 className={`${path[1] === 'personal'?'hi-lt':''}`}>Personal Details</h6>
                    {path[1] === 'personal' ?
                        <span className='grey-bdr'></span> : path[1] === 'education' ?
                            <>
                                <span className='grey-bdr'></span>
                                <span className='animated-bdr'></span>
                            </> :
                            <span className='completed-bdr'></span>
                    }
                </div>
                <div className='d-flex flex-column align-items-center task-box flex-fill'>
                    <Image ref={eduRef}
                        src={`/images/task_progress_bar/${(path[1] === 'personal' || path[1] === 'education') ? 'inActive_dot' : 'completed_dot'}.svg`}
                    />
                    <h6 className={`${path[1] === 'education'?'hi-lt':''}`}>Education Details</h6>
                    {(path[1] === 'personal' || path[1] === 'education') ?
                        <span className='grey-bdr'></span> : path[1] === 'professional' ?
                            <>
                                <span className='grey-bdr'></span>
                                <span className='animated-bdr'></span>
                            </> :
                            <span className='completed-bdr'></span>
                    }
                </div>
                <div className='d-flex flex-column align-items-center task-box flex-fill'>
                    <Image ref={proRef}
                        src={`/images/task_progress_bar/${(path[1] === 'personal' || path[1] === 'education' || path[1] === 'professional') ?
                            'inActive_dot' : 'completed_dot'}.svg`}
                    />
                    <h6 className={`${path[1] === 'professional'?'hi-lt':''}`}>Professional Details</h6>
                    {(path[1] === 'personal' || path[1] === 'education' || path[1] === 'professional') ?
                        <span className='grey-bdr'></span> : path[1] === 'document' ?
                            <>
                                <span className='grey-bdr'></span>
                                <span className='animated-bdr'></span>
                            </> :
                            <span className='completed-bdr'></span>
                    }
                </div>
                <div className='d-flex flex-column align-items-center task-box flex-fill'>
                    <Image ref={docRef}
                        src={`/images/task_progress_bar/${(path[1] === 'personal' || path[1] === 'education' || path[1] === 'professional' ||
                            path[1] === 'document') ? 'inActive_dot' : 'completed_dot'}.svg`}
                    />
                    <h6 className={`${path[1] === 'document'?'hi-lt':''}`}>Document Details</h6>
                    {(path[1] === 'personal' || path[1] === 'education' || path[1] === 'professional' || path[1] === 'document') ?
                        <span className='grey-bdr last-bdr'></span> : path[1] === 'declaration' ?
                            <>
                                <span className='grey-bdr last-bdr'></span>
                                <span className='grey-bdr last-atd'></span>
                            </> :
                            <span className='completed-bdr last-bdr'></span>
                    }
                </div>
                <div className='d-flex flex-column align-items-center task-box flex-fill'>
                    <Image ref={dlrRef}
                        src={`/images/task_progress_bar/${(path[1] === 'personal' || path[1] === 'education' || path[1] === 'professional' ||
                            path[1] === 'document' || path[1] === 'declaration') ? 'inActive_dot' : 'completed_dot'}.svg`}
                    />
                    <h6 className={`${path[1] === 'declaration'?'hi-lt':''}`}>Declaration</h6>
                </div>
            </div>
        </div>
    );
};

export default TaskProgressBar;