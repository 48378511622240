import React, { useState } from 'react';
import { Modal, Image, Button } from 'react-bootstrap';
import "./wishModal.scss";
import { ModalData } from '../../pages/PersonalDetails';

interface PropsObject {
    "modalData": ModalData,
    "callBack": () => void,
    "companyName": string
}

const WishModal = (props: PropsObject) => {

    return (
        <Modal
            show={props?.modalData?.modalShow}
            dialogClassName="custom-modal"
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Body>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <Image src='/images/components/celebrate_icon.svg' alt='celebrate' className='celebrate-img' />
                    <div className='d-flex justify-content-between align-items-center congrats-content'>
                        <Image src='/images/components/left_cracker.svg' alt='cracker' />
                        <div className='d-flex flex-column align-items-center'>
                            <p>Congratulations</p>
                            <h1>{props?.modalData?.empName}</h1>
                        </div>
                        <Image src='/images/components/right_cracker.svg' alt='cracker' />
                    </div>
                    <p className='cgts-msg'>
                        We're happy to welcome you to the Swipewire family! You've been selected for an<br />
                        exciting journey ahead, and we're confident that your skills and passion will be a<br />
                        huge asset.
                    </p>
                    <Button variant='dark' onClick={() => props?.callBack()}>Continue</Button>
                    <h6>{props?.companyName}</h6>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default WishModal;