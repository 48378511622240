import React, { useState } from 'react';
import { FileUploader } from "react-drag-drop-files";
import { Image } from "react-bootstrap";
import "./fileUpload.scss"

interface PropsObject {
    "fileValue": File | null | string,
    "fileName"?: string,
    "callBack": (file: File | null) => void,
    "isDisable"?: boolean,
    "customClass"?: string
}

const FileUpload = (props: PropsObject) => {
    const [fileTypes, setFileTypes] = useState<String[]>(["JPG", "PNG", "PDF"]);

    return (
        <div className='d-flex flex-column drag-drop-container'>
            <div className='file-container'>
                {props?.fileValue !== null && props?.fileValue !== "" ? (
                    <div className='d-flex flex-row align-items-center justify-content-between file-uld'>
                        <div className='d-flex flex-row file-uld-img align-items-center'>
                            {typeof props?.fileValue === "string" ?
                                <>
                                    <Image
                                        src={`/images/drag_drop/${['jpeg', 'png', 'svg', 'jpg'].includes(props?.fileName?.split('.').pop()?.toLowerCase() || '') ? 'jpg_icon' : 'pdf_icon'}.svg`}
                                        alt="file"
                                    />
                                    <p className='ms-2'>{props?.fileName}</p>
                                </> :
                                <>
                                    <Image
                                        src={`/images/drag_drop/${props?.fileValue?.type === 'image/jpeg' || props?.fileValue?.type === 'image/png' ? 'jpg_icon' : 'pdf_icon'}.svg`}
                                        alt="file"
                                    />
                                    <p className='ms-2'>{props?.fileValue?.name}</p>
                                </>
                            }
                        </div>
                        <Image
                            src='/images/drag_drop/close_icon.svg'
                            alt="close"
                            className='file-uld-close'
                            onClick={() => { !props?.isDisable && props?.callBack(null) }} // Set the file to null directly here
                        />
                    </div>
                ) : (
                    <FileUploader
                        handleChange={(file: File) => props?.callBack(file)}
                        name="myFile"
                        value={props?.fileValue}
                        types={fileTypes}
                        classes={`drag-drop ${props?.customClass}`}
                        children={<p>Click or Drag to Upload</p>}
                        maxSize={2}
                        onSizeError={(file: File) => props?.callBack(file)}
                        disabled={props?.isDisable}
                        onTypeError={(file: File) => props?.callBack(file)}
                    />
                )}
            </div>
            {!props?.fileValue &&
                <div className='d-flex flex-row justify-content-between f-type-cont'>
                    <p>Supported formats: .Jpeg, .Pdf & .Png</p>
                    <p>Max : 2MB</p>
                </div>
            }
        </div>

    );
};

export default FileUpload;