import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router";
import logo from "./logo.svg";
import Login from "./pages/Login";
import ProfessionalDetail from "./pages/ProfessionalDetails";
import PersonalDetails from "./pages/PersonalDetails";
import EducationalDetails from "./pages/EducationalDetails";
import Declaration from "./pages/Declaration";
import DocumentDetails from "./components/DocumentDetail/DocumentDetails";
import DocumentDetail from "./pages/DocumentDetail";
import ScrollToTop from "./components/ScrollToTop";
import SubmitModal from "./components/ModalPopups/SubmitModal";


export interface CompanyDetails {
	"id": number,
	"name": string,
	"logo": string,
	"domain": string
}

export interface CompanyProps {
	companyDetails: CompanyDetails
}

function App() {
	const [companyDetails, setCompanyDetails] = useState<CompanyDetails>({
		id: 1,
		name: "Swipewire technologies Private limited",
		logo: "https://hrmsapp.s3.ap-south-1.amazonaws.com/company_logos/swipewire%20Technologies/SVG%20&%20PNG/swipewire_black.svg",
		domain: "https://onboarding.swipewire.goshhr.com"
	});

	useEffect(() => {
		// Disable browser's default scroll restoration
		if ("scrollRestoration" in window.history) {
			window.history.scrollRestoration = "manual";
		}
		fetch(`${process.env.PUBLIC_URL}/json/company.json`)
			.then((response) => {
				if (!response.ok) {
					throw new Error("Network response was not ok");
				}
				return response.json();
			})
			.then((jsonData) => {
				const currentDomain = `${window.location.protocol}//${window.location.host}`;
				const matchedObject = jsonData.find((item: CompanyDetails) => item.domain === currentDomain);
				if (matchedObject) {
					// Add favicon dynamically
					const favicon = document.createElement("link");
					favicon.rel = "icon";
					favicon.href = matchedObject?.logo;
					document.head.appendChild(favicon);
					console.log(matchedObject?.logo)
					setCompanyDetails((prevState) => {
						return {
							...prevState,
							id: matchedObject?.id,
							name: matchedObject?.name,
							logo: matchedObject?.logo,
							domain: matchedObject?.domain
						}
					});
				}
			})
			.catch((error) => console.error("Error fetching JSON:", error));
	}, []);

	return (
		<div>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<Login companyDetails={companyDetails} />} />
				<Route path="/personal" element={<PersonalDetails companyDetails={companyDetails} />} />
				<Route path="/education" element={<EducationalDetails companyDetails={companyDetails} />} />
				<Route path="/professional" element={<ProfessionalDetail companyDetails={companyDetails} />} />
				<Route path="/document" element={<DocumentDetail companyDetails={companyDetails} />} />
				<Route path="/declaration" element={<Declaration companyDetails={companyDetails} />} />
				<Route path="/submit" element={<SubmitModal companyDetails={companyDetails} />} />
			</Routes>
		</div>
	);
}

export default App;
