import React, { useState } from 'react';
import { Image, Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "./calendar.scss";

interface PropsObject {
    "selectedDate": Date | null,
    "dateUpdate": (date: Date | null) => void,
    "dpType": string,
    "label": string,
    "minDate"?: Date,
    "maxDate"?: Date,
    "isDisable"?: boolean,
    "isError"?: boolean,
    "errorValue"?: string,
    "isMandatory"?: boolean
}

const Calendar = (props: PropsObject) => {
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const [isMonthPic, setIsMonthPic] = useState<boolean>(false);
    const [isYearPic, setIsYearPic] = useState<boolean>(false);

    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];

    const blockInvalidDateChar = (e: React.KeyboardEvent<HTMLElement>) => !/^[0-9-]+$/.test(e.key) && e.key !== "Backspace" && e.preventDefault();

    const handleKeyPress = (e: React.KeyboardEvent<HTMLElement>, value: string) => {
        const position = value?.length; // Current length of the input
        const char = e?.key;

        // Allow Backspace, Tab, and Arrow keys
        if (['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight'].includes(char)) {
            return;
        }

        if (props?.dpType === "month") {
            if (
                (position === 0 && !/[0-1]/.test(char)) || // First digit of month: 0-1
                (position === 1 && /[0]/.test(value[0]) && !/[0-9]/.test(char)) || // Second digit of month: 0-9 if first digit is '0'
                (position === 1 && /[1]/.test(value[0]) && !/[0-2]/.test(char)) || // Second digit of month: 0-2 if first digit is '1' 
                (position === 2 && char !== '-') ||       // Third character: must be a dash
                (position >= 3 && !/[0-9]/.test(char))    // Year digits: must be numbers
            ) {
                e.preventDefault();
            }
            // Prevent input if it exceeds `mm-yyyy` length
            if (value?.length >= 7) {
                e.preventDefault();
            }
        }
        else {
            // Allow only digits and dashes at valid positions
            if (
                (position === 0 && !/[0-3]/.test(char)) || // First digit of day: 0-3
                (position === 1 && /[0-2]/.test(value[0]) && !/[0-9]/.test(char)) || // Second digit of day: 0-9 if first digit is in between 0-2
                (position === 1 && /[3]/.test(value[0]) && !/[0-1]/.test(char)) || // Second digit of day: 0-1 if first digit is 3.
                (position === 2 && char !== '-') ||       // Third character: must be a dash
                (position === 3 && !/[0-1]/.test(char)) || // First digit of month: 0-1
                (position === 4 && /[0]/.test(value[3]) && !/[0-9]/.test(char)) || // Second digit of month: 0-9 if first digit is '0'
                (position === 4 && /[1]/.test(value[3]) && !/[0-2]/.test(char)) || // Second digit of month: 0-2 if first digit is '1'
                (position === 5 && char !== '-') ||       // Sixth character: must be a dash
                (position >= 6 && !/[0-9]/.test(char))    // Year digits: must be numbers
            ) {
                e.preventDefault();
            }

            // Prevent input if it exceeds `dd-mm-yyyy` length
            if (value?.length >= 10) {
                e.preventDefault();
            }
        }
    };


    const handleDateChange = (date: Date | null) => {
        if (props?.selectedDate) {
            if (isMonthPic) {
                date?.setDate(props?.selectedDate?.getDate());
                props?.dateUpdate(date as Date);
                setIsMonthPic(false);
            }
            else if (isYearPic) {
                date?.setFullYear(date?.getFullYear(), props?.selectedDate?.getMonth(), props?.selectedDate?.getDate());
                props?.dateUpdate(date as Date);
                setIsYearPic(false)
                setIsMonthPic(true);
            }
            else {
                props?.dateUpdate(date as Date);
            }
        }
        else {
            if (isMonthPic) {
                setIsMonthPic(false);
            }
            else if (isYearPic) {
                setIsYearPic(false);
                setIsMonthPic(true);
            }
            props?.dateUpdate(date as Date);
        }
    }

    const parseAndValidateDate = (value: string): Date | null => {
        const regex = /^(\d{2})-(\d{2})-(\d{4})$/;
        const match = value.match(regex);

        if (match) {
            const day = parseInt(match[1], 10);
            const month = parseInt(match[2], 10) - 1; // Month is zero-indexed
            const year = parseInt(match[3], 10);

            const parsedDate = new Date(year, month, day);

            // Validate the parsed date matches the input (to avoid invalid dates like 31-02-2023)
            if (
                parsedDate.getDate() === day &&
                parsedDate.getMonth() === month &&
                parsedDate.getFullYear() === year
            ) {
                return parsedDate;
            }
        }

        return null;
    };


    return (
        <div className='dp-container'>
            <Form>
                <Form.Group className="int-grp dob">
                    <Form.Label className={props?.isDisable ? 'dis-lbl' : ''}>{props?.label} {props?.isMandatory && <span>*</span>}</Form.Label>
                    <DatePicker
                        renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                        }) => {
                            const currentYear = date.getFullYear();

                            return (
                                <div className="d-flex flex-row justify-content-between align-items-center cal-header">
                                    <Image
                                        src='/images/date_picker/left_arrow.svg'
                                        onClick={() => {
                                            if (isYearPic) {
                                                // Decrease the visible year range by 10 years
                                                changeYear(currentYear - 10);
                                            }
                                            else if (isMonthPic || props?.dpType === 'month') {
                                                changeYear(currentYear - 1); // Decrease by 1 year
                                            }
                                            else {
                                                decreaseMonth();
                                            }
                                        }}
                                    />
                                    <p className='mb-0'>
                                        <span
                                            onClick={() => {
                                                if (props?.dpType !== "month") {
                                                    setIsMonthPic(true);
                                                    setIsYearPic(false);
                                                }
                                            }}
                                        >
                                            {months[date.getMonth()]}
                                        </span>&nbsp;&nbsp;&nbsp;
                                        <span
                                            onClick={() => {
                                                setIsYearPic(true);
                                                setIsMonthPic(false);
                                            }}
                                        >
                                            {currentYear}
                                        </span>
                                    </p>
                                    <Image
                                        src='/images/date_picker/right_arrow.svg'
                                        onClick={() => {
                                            if (isYearPic) {
                                                // Increase the visible year range by 10 years
                                                changeYear(currentYear + 10);
                                            } else if (isMonthPic || props?.dpType === 'month') {
                                                changeYear(currentYear + 1); // Increase by 1 year
                                            } else {
                                                increaseMonth();
                                            }
                                        }}
                                    />
                                </div>
                            );
                        }}
                        selected={props?.selectedDate}
                        closeOnScroll={true}
                        dateFormat={props?.dpType === "month" ? "MM-yyyy" : "dd-MM-yyyy"}
                        placeholderText={props?.dpType === "month" ? "MM-YYYY" : "DD-MM-YYYY"}
                        showMonthYearPicker={isMonthPic || props?.dpType === "month"}
                        showYearPicker={isYearPic}
                        onKeyDown={(e) => handleKeyPress(e, (e.target as HTMLInputElement).value)} // Keypress restriction
                        onClickOutside={() => { setIsMonthPic(false); setIsYearPic(false) }}
                        calendarClassName={((isMonthPic && !isYearPic) || (props?.dpType === "month" && !isYearPic)) ? 'month-cal' : isYearPic ? 'year-cal' : ''}
                        shouldCloseOnSelect={(!isMonthPic && !isYearPic) || (props?.dpType === "month" && !isYearPic) ? true : false}
                        onChange={(date) => { handleDateChange(date) }}
                        minDate={props?.minDate}
                        maxDate={props?.maxDate}
                        disabled={props?.isDisable}
                        popperPlacement="bottom-end"
                        className={props?.isError ? 'err-msg' : ''}
                        onChangeRaw={(e) => {
                            const inputValue = e && (e.target as HTMLInputElement).value;
                            const parsedDate = parseAndValidateDate(inputValue ? inputValue : "");

                            if (parsedDate) {
                                handleDateChange(parsedDate);
                            }
                            else {
                                props?.dateUpdate(null); // Clear date if invalid
                            }
                        }}
                    />
                    <Image src='/images/calendar_icon.svg' alt='Calendar' />
                    {props?.isError && <Form.Text>{props?.errorValue === "" ? "Please enter the date" : props?.errorValue}</Form.Text>}
                </Form.Group>
            </Form>
        </div>
    );
};

export default Calendar;
