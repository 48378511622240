import React, { useState, useEffect, useRef } from "react";
import { Image } from "react-bootstrap";
import "./styles.scss";
interface OptionType {
  value: string;
  title: string;
}
interface SelectProps {
  value: string;
  options: OptionType[];
  onChange: (selectedValue: string) => void;
  placeholder: string;
  width?: string | "100%";
  margin?: string | "0";
  disabled?: boolean | false;
  className?: string;
}

const Select: React.FC<SelectProps> = ({
  value,
  options,
  onChange,
  placeholder,
  width,
  margin,
  disabled,
  className,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
  const optionRefs = useRef<(HTMLDivElement | null)[]>([]); // Refs for each option
  const searchTermRef = useRef<string>(""); // To store the last typed character
  const currentMatchIndexRef = useRef<number>(-1); // To track the index of the current match
  const typingTimeoutRef = useRef<NodeJS.Timeout | null>(null); // Timeout for resetting the search term

  // Close dropdown if user clicks outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        event.target &&
        !(event.target as Element)?.closest(".select-dropdown")
      ) {
        setIsOpen(false);
      }
    };

    const handleScroll = () => {
      // Close dropdown if page is scrolled
      // if (isOpen) {
      //   setIsOpen(false);
      // }
    };

    // Add event listeners
    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listeners when component is unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen]);

  useEffect(() => {

    if (options?.some((option: OptionType) => option?.value === value)) {
      options.forEach((option: OptionType) => {
        if (option?.value === value) {
          setInputValue(option?.title);
        }
      });
    }
    else {
      setInputValue("");
    }
    // }
  }, [value, options]);

  useEffect(() => {
    if (isOpen && inputValue) {
      // Scroll to the selected option when dropdown opens
      const selectedOptionIndex = options.findIndex(
        (option) => option.title === inputValue
      );
      if (
        selectedOptionIndex !== -1 &&
        optionRefs.current[selectedOptionIndex]
      ) {
        optionRefs.current[selectedOptionIndex]?.scrollIntoView({
          behavior: "smooth",
          block: "nearest", // Scroll the selected option into view
        });
      }
    }
  }, [isOpen, inputValue, options]);
  useEffect(() => {
    if (isOpen && highlightedIndex !== -1 && optionRefs.current[highlightedIndex]) {
      const optionElement = optionRefs.current[highlightedIndex];
      if (optionElement) {
        const optionRect = optionElement.getBoundingClientRect();

        if (optionRect.top < 0) {
          // Scroll up to make the option visible
          window.scrollBy(0, optionRect.top); // Add margin
        } else if (optionRect.bottom > window.innerHeight) {
          // Scroll down to make the option visible
          window.scrollBy(0, optionRect.bottom - window.innerHeight); // Add margin
        }
      }

    }
  }, [highlightedIndex, isOpen]);

  useEffect(() => {
    if (isOpen && highlightedIndex !== -1 && optionRefs.current[highlightedIndex]) {
      const optionElement = optionRefs.current[highlightedIndex];
      const dropdownContainer = optionElement?.parentElement;

      if (optionElement && dropdownContainer) {
        const optionRect = optionElement.getBoundingClientRect();
        const containerRect = dropdownContainer.getBoundingClientRect();

        // Check if option is outside of the visible dropdown container
        if (optionRect.top < containerRect.top) {
          // Scroll up to make it visible
          dropdownContainer.scrollTop -= containerRect.top - optionRect.top;
        } else if (optionRect.bottom > containerRect.bottom) {
          // Scroll down to make it visible
          dropdownContainer.scrollTop += optionRect.bottom - containerRect.bottom;
        }
      }
    }
  }, [isOpen, highlightedIndex]);



  const handleSelection = (
    e: React.MouseEvent<HTMLDivElement>,
    option: OptionType
  ) => {
    e.stopPropagation();
    setInputValue(option?.title);
    onChange(option?.value); // Pass the selected value to the parent component
    setIsOpen(false); // Close the dropdown after selection
  };
  const handleDropdown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!disabled) setIsOpen(!isOpen);
    e.stopPropagation();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Tab") {
      // Close the dropdown on Tab key
      if (isOpen) {
        setIsOpen(false);
      }
      return; // Allow default Tab behavior for focus navigation
    }

    if (e.key === "Enter") {
      if (!isOpen && !disabled) {
        setIsOpen(true);
        return;
      } else if (isOpen) {
        let option = options[highlightedIndex];
        if (option) {
          setInputValue(option.title);
          onChange(option.value); // Pass selected value to parent component
        }
        setHighlightedIndex(-1);
        setIsOpen(false); // Close the dropdown after selection
      }
      return;
    }

    if (e.key === "ArrowDown") {
      e.preventDefault(); // Prevent default scroll behavior
      if (highlightedIndex === -1) {
        const selectedOptionIndex = options.findIndex(
          (option) => option.title === inputValue
        );
        setHighlightedIndex(
          selectedOptionIndex === -1 ? options.length - 1 : selectedOptionIndex)
      } else {
        setHighlightedIndex((prevIndex) =>
          prevIndex < options.length - 1 ? prevIndex + 1 : 0
        );
      }
      return;
    }

    if (e.key === "ArrowUp") {
      e.preventDefault(); // Prevent default scroll behavior

      if (highlightedIndex === -1) {
        const selectedOptionIndex = options.findIndex(
          (option) => option.title === inputValue
        );
        setHighlightedIndex(
          selectedOptionIndex === -1 ? options.length - 1 : selectedOptionIndex)
      } else {
        setHighlightedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : options.length - 1
        );
      }
      return;
    }

    if (!isOpen) return;

    const char = e.key.toLowerCase();
    if (char.length !== 1 || !/^[a-zA-Z0-9]$/.test(char)) return;

    // Search functionality for matching options
    if (searchTermRef.current === char) {
      const matches = options
        .map((option, index) => ({ option, index }))
        .filter(({ option }) => option.title.toLowerCase().startsWith(char));

      if (matches.length > 0) {
        currentMatchIndexRef.current =
          (currentMatchIndexRef.current + 1) % matches.length;
        const nextMatch = matches[currentMatchIndexRef.current];

        optionRefs.current[nextMatch.index]?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });

        setInputValue(nextMatch.option.title);
        onChange(nextMatch.option.value);
      }
    } else {
      searchTermRef.current = char;
      currentMatchIndexRef.current = 0;

      const firstMatchIndex = options.findIndex((option) =>
        option.title.toLowerCase().startsWith(char)
      );

      if (firstMatchIndex !== -1) {
        optionRefs.current[firstMatchIndex]?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });

        setInputValue(options[firstMatchIndex].title);
        onChange(options[firstMatchIndex].value);
      }
    }

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      searchTermRef.current = "";
      currentMatchIndexRef.current = -1;
    }, 1000);
  };


  return (
    <div className={`select-dropdown `} style={{ width, margin }} onKeyDown={handleKeyDown} // Listen for keydown events
      tabIndex={disabled ? -1 : 0}>
      <div
        className={` select-dropdown-text-field ${disabled && "disabled"} ${className}`}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => handleDropdown(e)}
      >
        <input
          value={inputValue}
          placeholder={placeholder}
          readOnly
          className={` ${disabled && "disabled"} dropinput`}
          tabIndex={-1}
        />
        <Image
          src="/images/components/dropdownarrow.svg"
          alt="Dropdown"
          className={`${isOpen && "dropOpen"}`}
        />
      </div>

      <div
        className={`select-dropdown-option-area ${isOpen && "dropOpened"}  ${options.length > 6 && "overflown-drop"
          }`}
      >
        {options.map((option, index) => (
          <div
            className={`${option.title === inputValue && "selected"} options ${index === highlightedIndex ? "highlighted" : ""
              }`}
            onClick={(e: React.MouseEvent<HTMLDivElement>) =>
              handleSelection(e, option)
            }
            key={option?.value}
            ref={(el) => (optionRefs.current[index] = el)}
          >
            <p>{option?.title}</p>
            {option.title === inputValue && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M4 7.99984L6.82843 10.8283L12.4847 5.17139"
                  stroke="#373857"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Select;
