import React, { useEffect, useState } from "react";
import "../../styles/professionaldetail.scss";
import { Button, Form } from "react-bootstrap";
import Select from "../select/Select";
import Calendar from "../datePicker/Calendar";
import FileUpload from "../fileUpload/FileUpload";
import TaskProgressBar from "../TaskProgressBar/TaskProgressBar";

import moment from "moment";
import Toast from "../Toaster/Toast";
import FooterLayout from "../FooterLayout";
import { useNavigate } from "react-router-dom";
import { commonGetService, commonPostservice, handleValidateEmail, handleValidateMobileNumber } from "../../utils/properties";
import { UserDetails } from "../../pages/Login";

interface ExperienceDetail {
    jobTitle: string;
    companyName: string;
    latestExperience: boolean | string;
    from: Date | null;
    to: Date | null;
    documents: Files
}
interface ExperienceInYears {
    years: number | string;
    months: number | string;
}
interface HrDetail {
    hrName: string;
    hrEmail: string;
    hrNumber: string;
}
interface Files {
    appointmentFile: File | null,
    appointmentFile_name: string
    appraisalFile: File | null,
    appraisalFile_name: string
    relievingFile: File | null,
    relievingFile_name: string
    payslipFile: File | null,
    payslipFile_name: string
    bankstatementFile: File | null
    bankstatementFile_name: string
}
const ProfessionalDetail = () => {

    const Navigate = useNavigate();
    let Years = [
        {
            value: "",
            title: "Select",
        },
        { value: "0 Years", title: "0 Year" },
        { value: "1 Years", title: "1 Year" },
        { value: "2 Years", title: "2 Years" },
        { value: "3 Years", title: "3 Years" },
        { value: "4 Years", title: "4 Years" },
        { value: "5 Years", title: "5 Years" },
        { value: "6 Years", title: "6 Years" },
        { value: "7 Years", title: "7 Years" },
        { value: "8 Years", title: "8 Years" },
        { value: "9 Years", title: "9 Years" },
        { value: "10 Years", title: "10 Years" },
        { value: "11 Years", title: "11 Years" },
        { value: "12 Years", title: "12 Years" },
        { value: "13 Years", title: "13 Years" },
        { value: "14 Years", title: "14 Years" },
        { value: "15 Years", title: "15 Years" },
    ];
    let Months = [
        {
            value: "",
            title: "Select",
        },
        { value: "0 Months", title: "0 Month" },
        { value: "1 Months", title: "1 Month" },
        { value: "2 Months", title: "2 Months" },
        { value: "3 Months", title: "3 Months" },
        { value: "4 Months", title: "4 Months" },
        { value: "5 Months", title: "5 Months" },
        { value: "6 Months", title: "6 Months" },
        { value: "7 Months", title: "7 Months" },
        { value: "8 Months", title: "8 Months" },
        { value: "9 Months", title: "9 Months" },
        { value: "10 Months", title: "10 Months" },
        { value: "11 Months", title: "11 Months" },
    ];

    const [fresher, setFresher] = useState<boolean>(false);
    const [totalExperience, setTotalExperience] = useState<ExperienceInYears>({ years: 0, months: 0 });
    const [relevantExperience, setRelevantExperience] = useState<ExperienceInYears>({ years: "", months: "" });
    const [workExperiences, setWorkExperiences] = useState<ExperienceDetail[]>([
        { jobTitle: "", companyName: "", latestExperience: false, from: null, to: null, documents: { appointmentFile: null, appraisalFile: null, relievingFile: null, payslipFile: null, bankstatementFile: null, appointmentFile_name: "", appraisalFile_name: "", relievingFile_name: "", payslipFile_name: "", bankstatementFile_name: "" } },
    ]);
    const [hrDetails, setHrDetails] = useState<HrDetail>({
        hrName: "",
        hrEmail: "",
        hrNumber: "",
    });


    const [relevantExperienceErrors, setRelevantExperienceErrors] = useState({
        years: "",
        months: "",
    });

    const [workExperienceErrors, setWorkExperienceErrors] = useState<
        { jobTitle: string; companyName: string; from: string | null; to: string | null, documents: { appointmentFile: string, relievingFile: string, payslipFile: string, bankstatementFile: string } }[]
    >([
        { jobTitle: "", companyName: "", from: null, to: null, documents: { appointmentFile: "", relievingFile: "", payslipFile: "", bankstatementFile: "" } },
    ]);

    const [hrDetailsErrors, setHrDetailsErrors] = useState({
        hrName: "",
        hrEmail: "",
        hrNumber: "",
    });




    const [toast, showToast] = useState<boolean>(false);
    const [toastMsg, setToastMsg] = useState<string>("")
    const [uploadErr, setUploadErr] = useState<string>("");
    const [latestEnabled, setLatestEnabled] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const handleExperienceChange = () => {
        if (!fresher && !latestEnabled) {
            setWorkExperiences((prevWorkExperiences) => [
                ...prevWorkExperiences,
                { jobTitle: "", companyName: "", latestExperience: false, from: null, to: null, documents: { appointmentFile: null, appraisalFile: null, relievingFile: null, payslipFile: null, bankstatementFile: null, appointmentFile_name: "", appraisalFile_name: "", relievingFile_name: "", payslipFile_name: "", bankstatementFile_name: "" } },
            ]);
            setWorkExperienceErrors([
                ...workExperienceErrors,
                { jobTitle: "", companyName: "", from: null, to: null, documents: { appointmentFile: "", relievingFile: "", payslipFile: "", bankstatementFile: "" } },
            ])
        }
    };

    const removeExperience = (index: number) => {
        if (!fresher) {
            const updatedExperiences = workExperiences.filter((_, i) => i !== index);
            setWorkExperiences(updatedExperiences);
            const updatedExperiencesErrors = workExperienceErrors.filter((_, i) => i !== index);
            setWorkExperienceErrors(updatedExperiencesErrors);
        }
    };

    const handleChange = (
        index: number,
        field: keyof ExperienceDetail,
        value: string | boolean | Date | null
    ) => {
        setWorkExperiences((prevExperiences) => {
            value = typeof value === "string" && value.startsWith(" ") ? value.trim() : value;


            if (field === "latestExperience" && value === true) {
                setLatestEnabled(true)
                return prevExperiences.map((exp, i) => ({
                    ...exp,
                    latestExperience: i === index,
                }));
            }
            if (field === "latestExperience" && value === false) {
                setLatestEnabled(false)
            }
            if (field === "from" || field === "to") {
                return prevExperiences.map((exp, i) => {
                    if (i === index) {
                        const updatedExp = { ...exp, [field]: value };
                        return updatedExp;
                    }
                    return exp;
                });
            }

            return prevExperiences.map((exp, i) =>
                i === index ? { ...exp, [field]: value } : exp
            );
        });
        setWorkExperienceErrors((prevErrors) => {
            return prevErrors.map((exp, i) =>
                i === index ? { ...exp, [field]: "" } : exp
            );
        })
    };
    const handleHrDetails = (field: keyof HrDetail, value: string) => {

        if (field === "hrNumber") {
            if (value.length > 10) return;
        }
        value = value.startsWith(" ") ? value.trim() : value;
        if (field !== "hrNumber") {
            setHrDetails((prevDetails) => ({
                ...prevDetails,
                [field]: value,
            }));
        }
        if (field === "hrNumber") {

            if (value.length <= 10 && handleValidateMobileNumber(value) && /^[0-9]+$/.test(value) || value === "") {
                setHrDetailsErrors((prevErrors) => ({
                    ...prevErrors,
                    hrNumber: "",
                }))
                setHrDetails((prevDetails) => ({
                    ...prevDetails,
                    hrNumber: value,
                }));
            }

        } else if (field === "hrEmail") {
            let regex = /^[a-zA-Z0-9](\.?[a-zA-Z0-9_-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;
            if (!regex.test(value)) {
                setHrDetailsErrors((prevErrors) => ({
                    ...prevErrors,
                    hrEmail: "Invalid email format.",
                }))
            } else {
                setHrDetailsErrors((prevErrors) => ({
                    ...prevErrors,
                    hrEmail: "",
                }))
            }

        } else {
            setHrDetailsErrors((prevErrors) => ({
                ...prevErrors,
                [field]: "",
            }))
        }
    };

    const calculateTotalExperience = () => {
        let totalMonths = 0;
        workExperiences.forEach(exp => {
            if (exp.from && exp.to) {
                const startDate = moment(exp.from);
                const endDate = moment(exp.to);
                const durationInMonths = endDate.diff(startDate, 'months') + 1;
                totalMonths += durationInMonths;
            }
        });

        const years = Math.floor(totalMonths / 12);
        const months = totalMonths % 12;
        setTotalExperience({ years, months });
    };

    const handleFileChange = (file: File | null, key: keyof Files, index: number) => {
        if (typeof file !== "string") {

            setWorkExperiences((prevExperiences) => {
                return prevExperiences.map((exp, i) => {
                    if (i === index) {
                        const updatedExp = { ...exp, documents: { ...exp.documents, [key]: file, [key + "_name"]: "" } };
                        return updatedExp;
                    }
                    return exp;
                });
            })

            setWorkExperienceErrors((prevErrors) => {
                return prevErrors.map((exp, i) =>
                    i === index ? { ...exp, documents: { ...exp.documents, [key]: "" } } : exp
                );
            })


        } else {
            setUploadErr(file)
        }
    };


    const validateRelevantExperience = () => {
        const errors = { years: "", months: "" };
        if (relevantExperience.years === "" || isNaN(Number(relevantExperience.years)) || Number(relevantExperience.months) < 0) {
            errors.years = "Years must be a valid number.";
        }

        if (relevantExperience.months === "" || isNaN(Number(relevantExperience.months)) || Number(relevantExperience.months) < 0 || Number(relevantExperience.months) > 11) {
            errors.months = "Months must be between 0 and 11.";
        }

        setRelevantExperienceErrors(errors);
        return !errors.years && !errors.months;
    };


    const validateWorkExperiences = () => {
        const errors = workExperiences.map((experience) => ({
            jobTitle: experience.jobTitle ? "" : "Job title is required.",
            companyName: experience.companyName ? "" : "Company name is required.",
            from: experience.from ? "" : "Start date is required.",
            to: experience.to ? "" : "End date is required.",
            documents: {
                appointmentFile: experience.documents.appointmentFile ? "" : "Appointment file is required.",
                relievingFile: experience.documents.relievingFile ? "" : "Relieving file is required.",
                payslipFile: experience.documents.payslipFile ? "" : "Payslip file is required.",
                bankstatementFile: experience.documents.bankstatementFile ? "" : "Bank statement file is required.",
            }
        }));

        // Validate date range
        errors.forEach((error, index) => {
            const experience = workExperiences[index];
            if (experience.from && experience.to && new Date(experience.from) > new Date(experience.to)) {
                error.to = "End date must be after start date.";
            }
        });

        setWorkExperienceErrors(errors);
        return errors.every(
            (error) =>
                !error.jobTitle && !error.companyName && !error.from && !error.to && !error.documents.appointmentFile && !error.documents.relievingFile && !error.documents.payslipFile && !error.documents.bankstatementFile
        );
    };

    const validateHrDetails = () => {
        const errors = {
            hrName: hrDetails.hrName ? "" : "HR name is required.",
            hrEmail: !handleValidateEmail(hrDetails.hrEmail)
                ? ""
                : "Invalid email format.",
            hrNumber: /^\d{10}$/.test(hrDetails.hrNumber)
                ? ""
                : "HR number must be a 10-digit number.",
        };

        setHrDetailsErrors(errors);
        return !errors.hrName && !errors.hrEmail && !errors.hrNumber;
    };




    const validateAll = () => {
        const isRelevantExperienceValid = validateRelevantExperience();
        const isWorkExperienceValid = validateWorkExperiences();
        const isHrDetailsValid = validateHrDetails();

        return isRelevantExperienceValid && isWorkExperienceValid && isHrDetailsValid;
    };
    const onSubmit = () => {
        if (!fresher) {
            if (!validateAll()) {
                window.scrollTo(0, 0);
                showToast(true);
                setToastMsg("Please fill required  fields")

                setTimeout(() => {
                    showToast(false)
                    setToastMsg("")

                }, 2000)
                return
            }
        }
        const formData = new FormData();
        setIsLoading(true);
        if (!fresher) {


            // Format work experiences
            let experiences = workExperiences.map((experience) => ({
                ...experience,
                from: moment(experience.from).format("MM-YYYY"),
                to: moment(experience.to).format("MM-YYYY"),
            }));

            experiences.forEach((experience, index) => {
                // Append basic details
                formData.append(`work_experience[${index}][jobTitle]`, experience.jobTitle);
                formData.append(`work_experience[${index}][companyName]`, experience.companyName);
                formData.append(`work_experience[${index}][latestExperience]`, experience.latestExperience ? "1" : "0");
                formData.append(`work_experience[${index}][from]`, experience.from || "");
                formData.append(`work_experience[${index}][to]`, experience.to || "");

                // Append documents if they exist
                Object.entries(experience.documents).forEach(([key, file]) => {
                    if (file) {
                        formData.append(`work_experience[${index}][documents][${key}]`, file);
                    }
                });
            });

            // Append other data directly to formData
            formData.append("total_experience", JSON.stringify(totalExperience));
            formData.append("relevant_experience", JSON.stringify(relevantExperience));
            formData.append("hr_details", JSON.stringify(hrDetails));
        }
        formData.append("fresher", `${fresher ? 1 : 0}`); // Convert boolean to string

        commonPostservice(`onboard/form_update/professional_details`, formData).then(res => {
            if (res.status) {
                const userDetails: UserDetails | undefined = getProfileInfo();
                if (userDetails) {
                    userDetails.completed_steps.professional_details = true;
                    sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
                    Navigate("/document");
                }
            }
            else {
                setToastMsg(res.message)
                showToast(true);
                setTimeout(() => {
                    showToast(false)
                    setToastMsg("")
                }, 2000)
            }
            setIsLoading(false);

        })


    }

    const getProfileInfo = () => {
        const userDetailsString = sessionStorage.getItem('userDetails');
        if (userDetailsString) {
            const userDetails: UserDetails = JSON.parse(userDetailsString);
            return userDetails;
        }
    }


    const handleFresher = (value: boolean) => {
        setFresher(value);
        if (value) {
            setWorkExperiences([
                { jobTitle: "", companyName: "", latestExperience: false, from: null, to: null, documents: { appointmentFile: null, appraisalFile: null, relievingFile: null, payslipFile: null, bankstatementFile: null, appointmentFile_name: "", appraisalFile_name: "", relievingFile_name: "", payslipFile_name: "", bankstatementFile_name: "" } },
            ]);
            setTotalExperience({ years: 0, months: 0 });
            setRelevantExperience({ years: 0, months: 0 });
            setHrDetails({ hrName: "", hrEmail: "", hrNumber: "" });
            setWorkExperienceErrors([
                { jobTitle: "", companyName: "", from: null, to: null, documents: { appointmentFile: "", relievingFile: "", payslipFile: "", bankstatementFile: "" } },
            ])
            setRelevantExperienceErrors({ years: "", months: "" });
            setHrDetailsErrors({ hrName: "", hrEmail: "", hrNumber: "" });
            setLatestEnabled(false);
        }
    }

    useEffect(() => {
        calculateTotalExperience();
    }, [workExperiences]);

    useEffect(() => {
        commonGetService(`onboard/get_details/professional`).then(res => {
            if (res.status) {
                // Extract the personal details step
                if (res.data) {
                    if (res.data.data.fresher !== "1"
                    ) {
                        setTotalExperience(res.data.data.total_experience);
                        setRelevantExperience(res.data.data.relevant_experience);

                        const errors = res.data?.data?.work_experience.map(() => ({
                            jobTitle: null,
                            companyName: null,
                            from: null,
                            to: null,
                            documents: {
                                appointmentFile: null,
                                appraisalFile: null,
                                relievingFile: null,
                                payslipFile: null,
                                bankstatementFile: null,
                            },
                        }));
                        setWorkExperienceErrors(errors);
                        const dateObjectCreation = (dateString: Date | null) => {
                            const parsedDate = moment(dateString, "DD-YYYY", true);
                            if (parsedDate.isValid()) {
                                return parsedDate.month(0).toDate(); // Set the desired month (0 = January, 11 = December)
                            } else {
                                return null;
                            }
                        }
                        let experience = res.data.data.work_experience.map((exp: ExperienceDetail) => {
                            return {
                                ...exp, from: dateObjectCreation(exp.from), // Convert "MM-YYYY" to Date object
                                to: dateObjectCreation(exp.to),
                                latestExperience: exp?.latestExperience === "1"
                            }
                        })
                        const isLatestEnabled = experience.some((exp: ExperienceDetail) => exp.latestExperience);

                        setLatestEnabled(isLatestEnabled);
                        setWorkExperiences(experience);
                        setHrDetails(res.data.data.hr_details);
                    }
                    setFresher(res.data.data.fresher === "1" ? true : false);
                }
            }
            else {

            }
            setIsLoading(false);
        })
    }, [])

    return (

        <div className="container-work">
            <TaskProgressBar empName={'Vikrant'} />
            {toast && (
                <Toast
                    message={
                        toastMsg
                    }
                    status={"error"}
                />
            )}

            <div className="professionaldetail">

                <h1>Professional Details</h1>
                <Form.Check
                    type={"checkbox"}
                    id={`default-${"fresher"}`}
                    label={`Fresher`}
                    className="fresher-checkbox"
                    checked={fresher}
                    onChange={(e) => handleFresher(e.target.checked)}
                />
                <p>
                    <label className={`label ${fresher && "disabled"}`}>
                        {" "}
                        Total Experience{" "}
                    </label>
                    <span className="red-star">*</span>
                </p>
                <div className="d-flex space-between">
                    <Select
                        value={totalExperience?.years.toString() + " Years"}
                        options={Years}
                        onChange={() => { }}
                        placeholder="Select Year"
                        width="49.192%"
                        margin="1rem 0"
                        disabled={true}
                    />
                    <Select
                        value={totalExperience?.months.toString() + " Months"}
                        options={Months}
                        onChange={() => { }}
                        placeholder="Select Month"
                        width="49.192%"
                        margin="1rem 0"
                        disabled={true}
                    />
                </div>
                <p>
                    <label className={`label ${fresher && "disabled"}`}>
                        {" "}
                        Relevant Experience{" "}
                    </label>
                    <span className="red-star">*</span>
                </p>
                <div className="d-flex space-between">
                    <div style={{ width: "49.192%" }}>
                        <Select
                            value={relevantExperience?.years.toString() + " Years"}
                            options={Years}
                            onChange={(value) => {
                                setRelevantExperience({ ...relevantExperience, years: parseInt(value) });
                                setRelevantExperienceErrors({ ...relevantExperienceErrors, years: "" })
                            }}
                            placeholder="Select Year"
                            width="100%"
                            margin="1rem 0"
                            disabled={fresher}
                            className={`${relevantExperienceErrors?.years && "error-msg"} `}
                        />
                        {relevantExperienceErrors?.years && <Form.Text className="color-red">{relevantExperienceErrors.years}</Form.Text>}
                    </div>
                    <div style={{ width: "49.192%" }}>
                        <Select
                            value={relevantExperience?.months.toString() + " Months"}
                            options={Months}
                            onChange={(value) => {

                                setRelevantExperience({ ...relevantExperience, months: parseInt(value) });
                                setRelevantExperienceErrors({ ...relevantExperienceErrors, months: "" })
                            }}
                            placeholder="Select Month"
                            width="100%"
                            margin="1rem 0"
                            disabled={fresher}
                            className={`${relevantExperienceErrors.months && "error-msg"} `}
                        />
                        {relevantExperienceErrors?.months && <Form.Text className="color-red">{relevantExperienceErrors.months}</Form.Text>}
                    </div>
                </div>

                {workExperiences.map((exp, index) => (
                    <>
                        <hr></hr>
                        <div className="experience-section d-flex justify-content-between align-items-center">
                            <h4 className={`experience-title ${fresher && "disabled"}`}>
                                {" "}
                                Work Experience-{index + 1}
                            </h4>
                            {workExperiences.length > 1 && (
                                <Button
                                    className="remove-experience d-flex align-items-center"
                                    onClick={() => removeExperience(index)}
                                >
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clip-path="url(#clip0_525_4366)">
                                            <path
                                                d="M5.33203 4.09491L5.33203 2.98058C5.33203 2.20405 5.33203 1.81579 5.56498 1.57455C5.79792 1.33331 6.17284 1.33331 6.92267 1.33331L9.07472 1.33331C9.82456 1.33331 10.1995 1.33331 10.4324 1.57455C10.6654 1.81579 10.6654 2.20405 10.6654 2.98058V4.09491"
                                                stroke="#6B6B77"
                                                stroke-width="1.5"
                                            />
                                            <path
                                                d="M9.66797 7.81012L6.33464 7.81012"
                                                stroke="#6B6B77"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M8.66797 10.4769L7.33464 10.4769"
                                                stroke="#6B6B77"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M11.9955 10.3399C11.896 11.2403 11.8267 11.8595 11.7252 12.3325C11.6267 12.7917 11.5122 13.0364 11.3675 13.2133C11.2419 13.3668 11.0933 13.5 10.9269 13.6079C10.7352 13.7323 10.4794 13.8193 10.0121 13.8668C9.53092 13.9157 8.9078 13.9166 8.00199 13.9166C7.09619 13.9166 6.47306 13.9157 5.99186 13.8668C5.52461 13.8193 5.26879 13.7323 5.07707 13.6079C4.91067 13.5 4.76204 13.3668 4.63648 13.2133C4.49181 13.0364 4.37727 12.7917 4.27874 12.3325C4.17727 11.8595 4.10797 11.2403 4.00853 10.3399L3.34919 4.37024L12.6548 4.37024L11.9955 10.3399Z"
                                                stroke="#6B6B77"
                                                stroke-width="1.5"
                                            />
                                            <path
                                                d="M2 4.0813H14"
                                                stroke="#6B6B77"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_525_4366">
                                                <rect width="16" height="16" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    &nbsp; Remove{" "}
                                </Button>
                            )}
                        </div>
                        <p>
                            <label className={`label ${fresher && "disabled"}`}>
                                {" "}
                                Job title
                            </label>
                            <span className="red-star">*</span>
                        </p>
                        <Form.Control
                            type="text"
                            placeholder=""
                            className={`${workExperienceErrors[index].jobTitle && "error-msg"}`}

                            value={exp?.jobTitle}
                            disabled={fresher}
                            onChange={(e) => handleChange(index, "jobTitle", e.target.value)}
                        />
                        {workExperienceErrors[index].jobTitle && <Form.Text className="color-red">{workExperienceErrors[index].jobTitle}</Form.Text>}

                        <p>
                            <label className={`label ${fresher && "disabled"}`}>
                                {" "}
                                Company name
                            </label>
                            <span className="red-star">*</span>
                        </p>
                        <Form.Control
                            className={`${workExperienceErrors[index].companyName && "error-msg"}`}
                            type="text"
                            placeholder=""
                            value={exp?.companyName}
                            disabled={fresher}
                            onChange={(e) => handleChange(index, "companyName", e.target.value)}
                        />
                        {workExperienceErrors[index].companyName && <Form.Text className="color-red">{workExperienceErrors[index].companyName}</Form.Text>}

                        <div className="d-flex flex-row justify-content-between gap-1 w-100">
                            <div className="w-50">

                                <Calendar selectedDate={exp?.from} dateUpdate={(date: Date | null) => {
                                    if (date !== null) {
                                        handleChange(index, "from", date);
                                    }
                                }}
                                    dpType={'month'}
                                    label={'From'}
                                    // minDate={new Date("2024-11-05")}
                                    maxDate={exp?.to ?? undefined}
                                    isDisable={fresher}
                                    isError={!workExperienceErrors[index].from ? false : true}
                                    isMandatory={true}

                                />
                                {/* {workExperienceErrors[index].from && <Form.Text className="color-red">{workExperienceErrors[index].from}</Form.Text>} */}

                            </div>
                            <div className="w-50">

                                <Calendar selectedDate={exp?.to} dateUpdate={(date: Date | null) => {
                                    if (date !== null) {
                                        handleChange(index, "to", date);
                                    }
                                }}
                                    dpType={'month'}
                                    label={'To'}
                                    minDate={exp?.from ?? undefined}
                                    // maxDate={new Date()}
                                    isDisable={fresher}
                                    isError={!workExperienceErrors[index].to ? false : true}
                                    isMandatory={true}

                                />
                                {/* {workExperienceErrors[index].to && <Form.Text className="color-red">{workExperienceErrors[index].to}</Form.Text>} */}

                            </div>
                        </div>
                        <Form.Check
                            type={"checkbox"}
                            id={`default-${"fresher"}`}
                            label={`My last work experience`}
                            className="last-experience"
                            checked={!!exp?.latestExperience}
                            disabled={fresher}
                            onChange={(e) =>
                                handleChange(index, "latestExperience", e.target.checked)
                            }
                        />
                        <p>
                            <label className={`label ${fresher && "disabled"}`}>
                                Appointment Letter
                            </label>
                            <span className="red-star">*</span>
                        </p>
                        <FileUpload fileValue={exp?.documents?.appointmentFile} fileName={exp?.documents?.appointmentFile_name} callBack={(e: any) => handleFileChange(e, "appointmentFile", index)} isDisable={fresher} />
                        {workExperienceErrors[index].documents?.appointmentFile && <Form.Text className="color-red">{uploadErr === "" ? workExperienceErrors[index].documents?.appointmentFile : uploadErr}</Form.Text>}

                        <p>
                            <label className={`label ${fresher && "disabled"}`}>
                                Appraisal Letter (If Any)
                            </label>
                            {/* <span className="red-star">*</span> */}
                        </p>
                        <FileUpload fileValue={exp?.documents?.appraisalFile} fileName={exp?.documents?.appraisalFile_name} callBack={(e: any) => handleFileChange(e, "appraisalFile", index)} isDisable={fresher} />
                        <p>
                            <label className={`label ${fresher && "disabled"}`}>
                                Releiving Letter
                            </label>
                            <span className="red-star">*</span>
                        </p>
                        <FileUpload fileValue={exp?.documents?.relievingFile} fileName={exp?.documents?.relievingFile_name} callBack={(e: any) => handleFileChange(e, "relievingFile", index)} isDisable={fresher} />
                        {workExperienceErrors[index].documents?.relievingFile && <Form.Text className="color-red">{uploadErr === "" ? "Upload relieving letter" : uploadErr}</Form.Text>}

                        <p>
                            <label className={`label ${fresher && "disabled"}`}>Pay Slips</label>
                            <span className="red-star">*</span>
                        </p>
                        <FileUpload fileValue={exp?.documents?.payslipFile} fileName={exp?.documents?.payslipFile_name} callBack={(e: any) => handleFileChange(e, "payslipFile", index)} isDisable={fresher} />
                        {workExperienceErrors[index].documents?.payslipFile && <Form.Text className="color-red">{uploadErr === "" ? "Upload payslip" : uploadErr} </Form.Text>}

                        <div className="bank-statement-section">
                            <p>
                                <label className={`label ${fresher && "disabled"}`}>
                                    Bank Statement
                                </label>

                                <span className="red-star">*</span>
                            </p>
                            <div className="d-flex statement-condition">
                                <p>Upload your last 3 months bank statement.</p>
                            </div>
                            <FileUpload fileValue={exp?.documents?.bankstatementFile} fileName={exp?.documents?.bankstatementFile_name} callBack={(e: any) => handleFileChange(e, "bankstatementFile", index)} isDisable={fresher} />
                            {workExperienceErrors[index].documents?.bankstatementFile && <Form.Text className="color-red">{uploadErr === "" ? "Upload bank statement" : uploadErr} </Form.Text>}
                        </div>
                    </>
                ))}


                <div className="experience-section hr">
                    <h4 className={`experience-title ${fresher && "disabled"}`}>
                        {" "}
                        HR Contact Details
                    </h4>
                </div>
                <p>
                    <label className={`label ${fresher && "disabled"}`}> HR name</label>
                    <span className="red-star">*</span>
                </p>
                <Form.Control
                    type="text"
                    placeholder=""
                    className={`${hrDetailsErrors.hrName && "error-msg"}`}

                    disabled={fresher}
                    value={hrDetails.hrName}
                    onChange={(e) => handleHrDetails("hrName", e.target.value)}
                />
                {hrDetailsErrors.hrName && <Form.Text className="color-red">{hrDetailsErrors.hrName}</Form.Text>}

                <p>
                    <label className={`label ${fresher && "disabled"}`}>
                        {" "}
                        HR email address
                    </label>
                    <span className="red-star">*</span>
                </p>
                <Form.Control
                    type="text"
                    placeholder=""
                    disabled={fresher}
                    value={hrDetails.hrEmail}
                    className={`${hrDetailsErrors.hrEmail && "error-msg"}`}

                    onChange={(e) => handleHrDetails("hrEmail", e.target.value)}
                />
                {hrDetailsErrors.hrEmail && <Form.Text className="color-red">Enter valid email</Form.Text>}

                <p>
                    <label className={`label ${fresher && "disabled"}`}>
                        {" "}
                        HR contact number
                    </label>
                    <span className="red-star">*</span>
                </p>
                <Form.Control
                    type="tel"
                    pattern="[0-9]*"
                    placeholder=""
                    disabled={fresher}
                    value={hrDetails.hrNumber}
                    className={`${hrDetailsErrors.hrNumber && "error-msg"}`}
                    onChange={(e) => handleHrDetails("hrNumber", e.target.value)}
                />
                {hrDetailsErrors.hrNumber && <Form.Text className="color-red">Enter valid contact number</Form.Text>}
                <hr></hr>

                <button
                    className={`add-employment-button ${fresher && "disabled"} ${latestEnabled && "disabled"}`}
                    onClick={handleExperienceChange}
                >
                    + &nbsp; Add Employment{" "}
                </button>



            </div>
            <FooterLayout handleSave={onSubmit} isDisable={isLoading} />
        </div>

    );
};

export default ProfessionalDetail;
