import React, { useState, useEffect } from 'react';
import HeaderLayout from '../components/HeaderLayout';
import { Button, Image } from 'react-bootstrap';
import "../styles/educationalDetails.scss";
import EduDetails from '../components/EducationalDetails/EduDetails';
import TaskProgressBar from '../components/TaskProgressBar/TaskProgressBar';
import FooterLayout from '../components/FooterLayout';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { commonGetService, commonPostservice } from '../utils/properties';
import Toast from '../components/Toaster/Toast';
import { CompanyProps } from '../App';
import { UserDetails } from './Login';
import { OptionObject } from './PersonalDetails';

interface EduSystem {
    "mark": string,
    "mark_system": string
}
export interface EducationDetails {
    "education": string,
    "edu_system": EduSystem,
    "passed_out": string,
    "course_name": string | null,
    "course_type": string | null,
    "school_name": string,
    "joining_year": Date | null,
    "passing_year": Date | null,
    "mark_sheet": string | File | null,
    "mark_sheet_name": string,
    "educationError"?: boolean,
    "courseNameError"?: boolean,
    "schoolNameError"?: boolean,
    "passedOutError"?: boolean,
    "fromDateError"?: boolean,
    "toDateError"?: boolean,
    "fromDateErrorValue": string,
    "toDateErrorValue": string,
    "markError"?: boolean,
    "marksheetError"?: boolean,
    "marksheetErrorValue"?: string
}

export type HandleData = (key: string, val: string | Date | File | null, ind?: number, subKey?: string) => void

const EducationalDetails = (props: CompanyProps) => {
    const [educationList, setEducationList] = useState<EducationDetails[]>(Array.from({ length: 2 })?.map((res, i) => {
        return {
            education: i === 0 ? "10th/SSLC" : "12th / HSC / Diploma",
            edu_system: {
                mark: "",
                mark_system: "grade"
            },
            passed_out: "",
            course_name: null,
            course_type: null,
            school_name: "",
            joining_year: null,
            passing_year: null,
            mark_sheet: "",
            mark_sheet_name: "",
            educationError: false,
            courseNameError: false,
            schoolNameError: false,
            passedOutError: false,
            fromDateError: false,
            toDateError: false,
            fromDateErrorValue: "",
            toDateErrorValue: "",
            markError: false,
            marksheetError: false,
            marksheetErrorValue: ""
        }
    }));
    const [educationCount, setEducationCount] = useState<number>(4);
    const [resetEducationList, setResetEducationList] = useState<EducationDetails[]>([]);
    const [isFilled, setIsFilled] = useState<boolean>(true);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);


    const Navigate = useNavigate();

    const passingYearOptions: OptionObject[] = Array.from({ length: 20 })?.map((res, i, arr) => {
        let dynamicYearValue = new Date().getFullYear() - (arr.length - i);
        return {
            value: dynamicYearValue.toString(),
            title: dynamicYearValue.toString()
        }
    })

    const courseOptions: OptionObject[] = [
        {
            value: "UG Degree",
            title: "UG Degree",
        },
        {
            value: "PG Degree",
            title: "PG Degree",
        }
    ];

    const gradeOptions: OptionObject[] = [
        {
            value: "A",
            title: "A",
        },
        {
            value: "A+",
            title: "A+",
        },
        {
            value: "B",
            title: "B",
        },
        {
            value: "B+",
            title: "B+",
        },
        {
            value: "C",
            title: "C",
        },
        {
            value: "D",
            title: "D",
        },
    ];


    useEffect(() => {
        setResetEducationList(JSON.parse(JSON.stringify(educationList)));
        getEducationDetails();
    }, [])

    const getEducationDetails = () => {
        setIsLoading(true);
        commonGetService(`onboard/get_details/educational`).then(res => {
            setIsLoading(false);
            if (res.status) {
                if (res?.data?.data) {
                    res?.data?.data?.education_details?.forEach((val: EducationDetails) => {
                        if (val.edu_system.mark_system === "percentage") {
                            val.edu_system.mark = val.edu_system.mark.split('%')[0]
                        }
                        val.educationError = false
                        val.courseNameError = false
                        val.schoolNameError = false
                        val.passedOutError = false
                        val.fromDateError = false
                        val.toDateError = false
                        val.fromDateErrorValue = ""
                        val.toDateErrorValue = ""
                        val.markError = false
                        val.marksheetError = false
                        val.marksheetErrorValue = ""
                        val.joining_year = val?.joining_year ? moment(val?.joining_year, "MM-YYYY").toDate() : val?.joining_year
                        val.passing_year = val?.passing_year ? moment(val?.passing_year, "MM-YYYY").toDate() : val?.passing_year
                        val.passed_out = val.passed_out ? val.passed_out : ""
                    })
                    setEducationList(res?.data?.data?.education_details);
                    setIsEdit(true);
                }
            }
            else {
                alert("Education details fetching data API error");
            }
        })
    }

    const handleAddEducation = () => {
        if (educationList.length < educationCount) {
            const newEducation = {
                education: "",
                edu_system: {
                    mark: "",
                    mark_system: "grade"
                },
                passed_out: "",
                course_name: "",
                course_type: "full time",
                school_name: "",
                joining_year: null,
                passing_year: null,
                mark_sheet: "",
                mark_sheet_name: "",
                educationError: false,
                courseNameError: false,
                schoolNameError: false,
                passedOutError: false,
                fromDateError: false,
                toDateError: false,
                fromDateErrorValue: "",
                toDateErrorValue: "",
                markError: false,
                marksheetError: false,
                marksheetErrorValue: ""
            };
            setEducationList([...educationList, newEducation]); // Create a new array
        }
    }

    const handleOnChange: HandleData = (keyName, val, ind, subKey) => {
        setEducationList((prevEducationList) =>
            prevEducationList.map((item, i) => {
                // Reset errors for all items
                const resetErrors = {
                    educationError: false,
                    courseNameError: false,
                    schoolNameError: false,
                    passedOutError: false,
                    fromDateError: false,
                    toDateError: false,
                    markError: false,
                    marksheetError: keyName === "marksheetErrorValue",
                    marksheetErrorValue: keyName === "marksheetErrorValue" && typeof val === "string" ? val : ""
                };

                if (i === ind) {
                    let errorField = keyName === "education" ? "educationError" : keyName === "course_name" ? "courseNameError" :
                        keyName === "school_name" ? "schoolNameError" : keyName === "passed_out" ? "passedOutError" :
                            keyName === "joining_year" ? "fromDateError" : keyName === "passing_year" ? "toDateError" :
                                keyName === "mark_sheet" ? "marksheetError" : keyName === "edu_system" ? "markError" :
                                    keyName !== "course_type" ? "marksheetErrorValue" : ""
                    // Update the matched item while resetting errors
                    if (keyName === "edu_system" && subKey) {
                        let tempEduSystem = JSON.parse(JSON.stringify(item.edu_system));

                        if ((subKey === "mark_system" && val === "grade") || subKey === "mark") {
                            tempEduSystem = { ...tempEduSystem, [subKey]: val }
                        }
                        else {
                            tempEduSystem = { mark: "", [subKey]: val }
                        }

                        return {
                            ...item,
                            edu_system: tempEduSystem,
                            // ...resetErrors,
                            [errorField]: false
                        };
                    }
                    else {
                        return errorField === "marksheetErrorValue" ? {
                            ...item,
                            marksheetError: true,
                            [keyName]: val
                        } : (errorField === "fromDateError" || errorField === "toDateError") ?
                            {
                                ...item,
                                [keyName]: val,
                                // ...resetErrors,
                                fromDateError: false,
                                toDateError: false
                            } :
                            {
                                ...item,
                                [keyName]: val,
                                // ...resetErrors,
                                [errorField]: false
                            }

                        // return {
                        //     ...item,
                        //     [keyName]: val,
                        //     // ...resetErrors,
                        //     [errorField]: false
                        // };
                    }
                }
                // Reset errors for unmatched items without changing other properties
                return {
                    ...item,
                    // ...resetErrors,
                };
            })
        );

    }

    const handleValidate = () => {
        let isValid = true;
        // Temporary error states
        const educationListErrors = educationList.map((item) => ({
            ...item,
        }));

        educationListErrors?.forEach((item) => {
            if (item.education === "") {
                item.educationError = true;
                isValid = false;
            }
            if (item.edu_system.mark.trim() === "") {
                item.markError = true;
                isValid = false;
            }
            if (item.school_name.trim() === "") {
                item.schoolNameError = true;
                isValid = false;
            }
            if (!item.mark_sheet || item.mark_sheet === "") {
                item.marksheetError = true;
                item.marksheetErrorValue = "";
                isValid = false
            }
            if ((item.education === "10th/SSLC" || item.education === "12th / HSC / Diploma") && item.passed_out === "") {
                item.passedOutError = true;
                item.courseNameError = false;
                item.fromDateError = false;
                item.toDateError = false;
                isValid = false;
            }
            if (item.education !== "10th/SSLC" && item.education !== "12th / HSC / Diploma") {
                item.passedOutError = false;
                if (item.course_name?.trim() === "") {
                    item.courseNameError = true;
                    isValid = false;
                }
                if (!item.joining_year) {
                    item.fromDateError = true;
                    isValid = false;
                }
                else if (item.passing_year && item.joining_year >= item.passing_year) {
                    item.fromDateError = true;
                    item.fromDateErrorValue = "From date should be greater than To date";
                    isValid = true;
                }
                if (!item.passing_year) {
                    item.toDateError = true;
                    isValid = false;
                }
                else if (item.joining_year && item.passing_year <= item.joining_year) {
                    item.toDateError = true;
                    item.toDateErrorValue = "To date should be lesser than From date";
                    isValid = false;
                }
            }
        })

        setEducationList(educationListErrors);
        return isValid;
    }

    const getProfileInfo = () => {
        const userDetailsString = sessionStorage.getItem('userDetails');
        if (userDetailsString) {
            const userDetails: UserDetails = JSON.parse(userDetailsString);
            return userDetails;
        }
    }

    const handleSave = () => {
        if (handleValidate()) {
            const formData = new FormData();
            educationList.forEach((item, index) => {
                formData.append(`education_details[${index}][education]`, item.education);
                formData.append(`education_details[${index}][edu_system][mark]`, item.edu_system.mark_system !== "grade" ? `${item.edu_system.mark}%` : item.edu_system.mark);
                formData.append(`education_details[${index}][edu_system][mark_system]`, item.edu_system.mark_system);
                formData.append(`education_details[${index}][passed_out]`, item.passed_out);
                formData.append(`education_details[${index}][course_name]`, item.course_name || "");
                formData.append(`education_details[${index}][course_type]`, item.course_type || "");
                formData.append(`education_details[${index}][school_name]`, item.school_name);
                formData.append(`education_details[${index}][joining_year]`, item.joining_year ? moment(item.joining_year).format("MM-YYYY") : "");
                formData.append(`education_details[${index}][passing_year]`, item.passing_year ? moment(item.passing_year).format("MM-YYYY") : "");
                formData.append(`education_details[${index}][mark_sheet]`, item.mark_sheet || "");
                isEdit && formData.append(`education_details[${index}][mark_sheet_name]`, item.mark_sheet_name);
            });

            setIsLoading(true);
            commonPostservice(`onboard/form_update/educational_details`, formData).then(res => {
                setIsLoading(false);
                if (res.status) {
                    const userDetails: UserDetails | undefined = getProfileInfo();
                    if (userDetails) {
                        userDetails.completed_steps.educational_details = true;
                        sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
                        Navigate('/professional');
                    }
                }
                else {
                    alert('error occured in API');
                }
            })
        }
        else {
            window.scrollTo(0, 0);
            setTimeout(() => {
                setIsFilled(false);
            }, 500)
            setTimeout(() => {
                setIsFilled(true);
            }, 1500)
        }
    }

    const handleReset = () => {
        setEducationList(JSON.parse(JSON.stringify(resetEducationList)));
    }

    const handleDelete = (ind: number) => {
        setEducationList((prevState) => {
            return prevState?.filter((res, i) => {
                return ind !== i
            })
        })
    }

    return (
        <HeaderLayout companyLogo={props?.companyDetails?.logo}>
            <div className='education-out-container'>
                <div className='education-in-container'>
                    <TaskProgressBar empName={'Vikrant'} />
                    <div className={`edu-details d-flex flex-column ${educationList?.length === educationCount ? 'add-none' : ''}`}>
                        <h2 className='pg-heading'>Educational Details</h2>
                        {educationList?.map((response, i) => (
                            <div className={`edu-container ${i > 0 ? 'top-border-dec' : ''}`} id={`${response?.education}+${i}`}>
                                <div className='edu-header d-flex justify-content-between align-items-center'>
                                    <h5>Education-{i + 1}</h5>
                                    {response?.education !== "12th / HSC / Diploma" &&
                                        <Button className='d-flex align-items-center' onClick={() => response?.education !== "10th/SSLC" ? handleDelete(i) : handleReset()} >
                                            <Image src={`/images/${response?.education !== "10th/SSLC" ? "delete_icon" : "reset_icon"}.svg`} alt='Delete' />
                                            <span>{response?.education !== "10th/SSLC" ? "Remove" : "Reset"}</span>
                                        </Button>
                                    }
                                </div>
                                <EduDetails
                                    ind={i}
                                    eduDetails={response}
                                    handleData={handleOnChange}
                                    passingYearOptions={passingYearOptions}
                                    // courseOptions={courseOptions}
                                    courseOptions={courseOptions?.filter(course => {
                                        if (educationList?.every(res => res.education !== course.value) || response?.education === course.value) {
                                            return course;
                                        }
                                    })}
                                    gradeOptions={gradeOptions}
                                />
                            </div>
                        ))}
                        {educationList?.length < educationCount &&
                            <div className='add-edu top-border-dec'>
                                <Button className='d-flex align-items-center' onClick={() => handleAddEducation()}>
                                    <Image src='/images/add_icon.svg' alt='Add' />
                                    <span>Add Education</span>
                                </Button>
                            </div>
                        }
                    </div>
                    <FooterLayout handleSave={handleSave} isDisable={isLoading} />
                </div>
            </div>
            {!isFilled && <Toast message='Please fill in all required fields' status='error' />}
        </HeaderLayout>
    );
};

export default EducationalDetails;