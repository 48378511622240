import React, { useEffect, useState } from 'react'
import HeaderLayout from '../components/HeaderLayout'
import { Form } from 'react-bootstrap'
import "../styles/professionaldetail.scss";
import PersonalDetailDeclaration from '../components/Declaration/PersonalDetailDeclaration/PersonalDetailDeclaration';
import EducationDeclaration from '../components/Declaration/EducationDeclaration/EducationDeclaration';
import ProfessionalDeclaration from '../components/Declaration/ProfessinalDetailDeclaration/ProfessionalDeclaration';
import DocumentDeclaration from '../components/Declaration/DocumentDeclaration/DocumentDetailDeclaration';
import TaskProgressBar from '../components/TaskProgressBar/TaskProgressBar';
import FooterLayout from '../components/FooterLayout';
import { commonGetService, commonPostservice } from '../utils/properties';
import { set } from 'react-datepicker/dist/date_utils';
import SubmitModal from '../components/ModalPopups/SubmitModal';
import { CompanyProps } from '../App';
import { UserDetails } from './Login';
import PermissionModal from '../components/ModalPopups/PermissionModal';
import { useNavigate } from 'react-router';
import Toast from '../components/Toaster/Toast';
import moment from 'moment';


export interface Basic {
    name: string
    date_of_birth: string,
    gender: string,
    personal_email_id: string,
    contact_number: string,
    marital_status: string,
    blood_group: string,
}

export interface Emergency {
    relationship: string,
    Full_name: string,
    contact_number: string
}

export interface Address {
    current_address: string,
    permanent_address: string
    current_address_proof_name?: string
    permanent_address_proof_name?: string

}

export interface ExtendedEducation {
    education: string,
    course?: string,
    course_type?: string,
    school_name?: string,
    joining_year?: string,
    passing_year?: string,
    percentage: string,
    "School/University  name"?: string,
    "from-To"?: string,
    "Percentage/Grade": string;
}

interface EducationDetail {
    education: string;
    school_name: string;
    "School/University name": string;
    "from-To": string;
    course?: string;
    course_type?: string;
    joining_year?: string;
    passing_year?: string;
    percentage?: string;
    passed_out?: string;
    edu_system?: {
        mark: string
    }
    mark_sheet_name?: string;
    course_name?: string;
}
interface Docs {

}

export interface WorkExperience {
    job_title: string;
    company_Name: string;
    "from-To": string;
    appointment_letter?: string;
    appraisal_letter?: string;
    releiving_letter?: string;
    pay_slip?: string;
    bank_statement?: string;
    from?: string;
    to?: string;
    jobTitle?: string;
    companyName?: string;
    appraisalFile_name?: string;
    appointmentFile_name?: string;
    relievingFile_name?: string;
    payslipFile_name?: string;
    bankstatementFile_name?: string;
}
export interface HrDetail {
    hr_name: string;
    hr_email_address: string;
    hr_contact_number: string;
}

export interface DocumentDetail {
    aadharDetails: {
        name_as_per_Aadhar: string;
        aadhar_number?: number | undefined | string;
        aadharFile?: File | null;
        aadharFileName?: string
    }
    panDetails: {
        name_as_per_PAN: string;
        pan_number?: number | undefined | string;
        panFile?: File | null;
        panFileName?: string
    }
    passportDetails: {
        passport_number?: number | undefined | string;
        issue_date?: number | undefined | string;
        expiry_date?: number | undefined | string;
        passportFileName?: string
    }

    bankDetails: {
        primaryAccount: {
            account_holder_name: number | undefined | string;
            account_number: number | undefined | string;
            IFSC_code: number | undefined | string;
            bank_name: number | undefined | string;
            branch: number | undefined | string;
            accountFileName?: string
        }
        secondaryAccount: {
            account_holder_name: number | undefined | string;
            account_number: number | undefined | string;
            IFSC_code: number | undefined | string;
            bank_name: number | undefined | string;
            branch: number | undefined | string;
            accountFileName?: string

        }
    }
    uan: { uan_number: string, },
    esi: { esi_number: string, },
    dependentDetails:
    {
        dependent_name: string
        Relationship: string
        Date_of_birth: string
        blood_group: string
        Occupation: string
    }[]


}

export interface ModalObject {
    modalShow: boolean
}

const Declaration = (props: CompanyProps) => {
    let details: DocumentDetail = {
        aadharDetails: {
            name_as_per_Aadhar: "",
            aadhar_number: "",
        },
        panDetails: {
            name_as_per_PAN: "",
            pan_number: "",
        },

        passportDetails: {
            passport_number: "",
            issue_date: "",
            expiry_date: "",
        },

        bankDetails: {
            primaryAccount: {
                account_holder_name: "",
                account_number: "",
                IFSC_code: "",
                bank_name: "",
                branch: "",
            },
            secondaryAccount: {
                account_holder_name: "",
                account_number: "",
                IFSC_code: "",
                bank_name: "",
                branch: "",
            },
        },
        uan: { uan_number: "", },
        esi: { esi_number: "", },
        dependentDetails: [{
            dependent_name: "",
            Relationship: "",
            Date_of_birth: "",
            blood_group: "",
            Occupation: "",
        }, {
            dependent_name: "",
            Relationship: "",
            Date_of_birth: "",
            blood_group: "",
            Occupation: "",
        },]
    }
    const [particularsVerified, setParticularsVerified] = React.useState(false)
    const [basicDetail, setBasicDetail] = useState<Basic>({
        name: "",
        date_of_birth: "",
        gender: "",
        personal_email_id: "",
        contact_number: "",
        marital_status: "",
        blood_group: "",
    })
    const [emergencyContactDetails, setEmergencyContactDetails] = useState<Emergency>({
        relationship: "",
        Full_name: "",
        contact_number: "string"
    })

    const [Addresses, setAddresses] = useState<Address>({
        current_address: "",
        permanent_address: ``,
        current_address_proof_name: "",
        permanent_address_proof_name: "",
    })
    const [educationDetails, setEducationDetails] = useState<ExtendedEducation[]>([
        {
            education: "",
            course: "",
            course_type: "",
            school_name: "",
            joining_year: "",
            passing_year: "",
            percentage: "",
            "School/University  name": "",
            "from-To": "",
            "Percentage/Grade": "",
        },
    ]);

    const [WorkExperiences, setWorkExperiences] = useState<WorkExperience[]>([
        { job_title: "", company_Name: "", "from-To": "", appointment_letter: "", appraisal_letter: "", releiving_letter: "", pay_slip: "", bank_statement: "" },
    ]);
    const [hrDetails, setHrDetails] = useState<HrDetail>({
        hr_name: "",
        hr_email_address: "",
        hr_contact_number: "",
    })
    const [profilePic, setProfilePic] = useState<string>("")

    const [totalExperience, setTotalExperience] = useState<string>("")
    const [relevantExperience, setRelevantExperience] = useState<string>("")

    const [documentDetails, setDocumentDetails] = useState<DocumentDetail>(details);
    // const [showModal, setShowModal] = useState<boolean>(false)
    const [modalData, setModalData] = useState<ModalObject>({ modalShow: false })
    const [isFresher, setIsFresher] = useState<boolean>(false)
    const [toast, showToast] = useState<boolean>(false);
    const [toastMsg, setToastMsg] = useState<string>("")
    const [checkboxError, setCheckboxError] = useState<boolean>(false)
    const Navigate = useNavigate();


    const getProfileInfo = () => {
        const userDetailsString = sessionStorage.getItem('userDetails');
        if (userDetailsString) {
            const userDetails: UserDetails = JSON.parse(userDetailsString);
            return userDetails;
        }
    }

    const handleDeclaration = (action: string) => {
        if (action === 'save') {
            if (particularsVerified) {
                commonPostservice(`onboard/form_update/declare_details_verified`, { details_verified: particularsVerified }).then(res => {
                    if (res.status) {
                        const userDetails: UserDetails | undefined = getProfileInfo();
                        if (userDetails) {
                            userDetails.completed_steps.declaration = true;
                            userDetails.form_status = "submit";
                            sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
                            // setShowModal(true)
                            Navigate('/submit');
                        }
                    } else {
                        showToast(true);
                        setToastMsg("Something went wrong, please try again")
                    }

                })
            }
        }
        setModalData((prevState) => { return { ...prevState, modalShow: false } })
    }

    const handlePermissionModal = () => {
        if (particularsVerified) { setModalData((prevState) => { return { ...prevState, modalShow: true } }) } else {
            window.scrollTo(0, document.body.scrollHeight);
            showToast(true);
            setToastMsg("Please verify your particulars to proceed")
            setCheckboxError(true)
            setTimeout(() => {
                showToast(false)
                setToastMsg("")

            }, 2000)
        }
    }


    useEffect(() => {

        commonGetService(`onboard/get_details/declare_verified`).then(res => {


            const personalDetailsStep = res.data.find((step: { step: string }) => step.step === "personal_details");

            // setIsLoading(false);
            if (res.status) {

                // Extract the personal details step
                const personalDetailsStep = res.data.find((step: { step: string }) => step.step === "personal_details");
                const educationalDetailsStep = res.data.find((step: { step: string }) => step.step === "educational_details");

                const professionalDetailsStep = res.data.find((step: { step: string }) => step.step === "professional_details");
                const documentDetailsStep = res.data.find((step: { step: string }) => step.step === "document_details");

                if (personalDetailsStep) {
                    const data = personalDetailsStep.data;

                    // Update basicDetail state
                    setBasicDetail({
                        name: data.name || "",
                        date_of_birth: data.date_of_birth || "",
                        gender: data.gender || "",
                        personal_email_id: data.personal_email_id || "",
                        contact_number: data.contact_number || "",
                        marital_status: data.marital_status || "",
                        blood_group: data.blood_group || "",
                    });
                    if (data?.employee_image) setProfilePic(data?.employee_image)

                    // Update emergencyContactDetails state
                    if (data.emergency_contact_details) {
                        setEmergencyContactDetails({
                            relationship: data.emergency_contact_details.relation_type || "",
                            Full_name: data.emergency_contact_details.name || "",
                            contact_number: data.emergency_contact_details.contact_number || "",
                        });
                    }

                    // Update Addresses state
                    setAddresses({
                        current_address: data.current_address?.full_address || "",
                        permanent_address: data.permanent_address?.full_address || "",
                        current_address_proof_name: (data?.current_address?.address_proof_name) || "",
                        permanent_address_proof_name: (data?.permanent_address?.address_proof_name) || ""
                    });
                }
                if (educationalDetailsStep) {
                    // setEducationDetails(educationalDetailsStep.data?.education_details);
                    const mappedEducationDetails = educationalDetailsStep.data?.education_details.map((edu: EducationDetail) => {
                        const mappedEdu: {
                            education: any;
                            school_name?: any;
                            "School/University name"?: any;
                            "from-To"?: string;
                            course?: any;
                            course_type?: any;
                            joining_year?: any; // Add the course property
                            passing_year?: any;
                            percentage?: any;
                            "Percentage/Grade"?: any;
                            mark_sheet_name?: string;

                        } = {
                            education: edu.education,
                            mark_sheet_name: edu.mark_sheet_name,

                        };

                        if (!edu.education.includes("10th") && !edu.education.includes("12")) mappedEdu.course = edu.course_name;
                        if (edu.course_type) mappedEdu.course_type = edu.course_type;
                        if (!edu.education.includes("10th") && !edu.education.includes("12")) mappedEdu["School/University name"] = edu.school_name;
                        else mappedEdu.school_name = edu.school_name;

                        if (!edu.education.includes("10th") && !edu.education.includes("12")) {
                            const from = edu.joining_year ? moment(edu.joining_year, "MM-YYYY").format("MMM YYYY") : "";
                            const to = edu.passing_year ? moment(edu.passing_year, "MM-YYYY").format("MMM YYYY") : "";
                            mappedEdu["from-To"] = `${from} - ${to}`;
                        }
                        if (edu.education.includes("10th") && edu.education.includes("12")) mappedEdu.joining_year = edu.joining_year;

                        if (edu.education.includes("10th") || edu.education.includes("12")) mappedEdu.passing_year = edu.passed_out;

                        if (edu.percentage) mappedEdu.percentage = edu.percentage;
                        if (edu.edu_system?.mark) mappedEdu["Percentage/Grade"] = edu.edu_system.mark;


                        return mappedEdu;
                    });


                    setEducationDetails(mappedEducationDetails);
                }

                if (professionalDetailsStep) {
                    if (professionalDetailsStep.data?.work_experience) {
                        const mappedProfessionalDetails = professionalDetailsStep.data?.work_experience.map((data: any) => {
                            let mappedData: any = {
                                // This will include all properties from data, including documents
                                job_title: data?.jobTitle ?? "",
                                company_Name: data?.companyName ?? "",
                                "from-To": `${moment(data["from"], "MM-YYYY").format("MMM YYYY")} - ${moment(data["to"], "MM-YYYY").format("MMM YYYY")}`,
                                appointment_letter: data.documents.appointmentFile_name,

                            }
                            if (data.documents.appraisalFile_name) mappedData.appraisal_letter = data.documents.appraisalFile_name;
                            mappedData.releiving_letter = data.documents.relievingFile_name;
                            mappedData.pay_slip = data.documents.payslipFile_name;
                            mappedData.bank_statement = data.documents.bankstatementFile_name;
                            return mappedData;

                        })
                        setWorkExperiences(mappedProfessionalDetails);

                        const hrDetails = professionalDetailsStep.data?.hr_details;
                        setHrDetails({
                            hr_name: hrDetails?.hrName || "",
                            hr_email_address: hrDetails?.hrEmail || "",
                            hr_contact_number: hrDetails?.hrNumber || "",
                        });
                        setTotalExperience(professionalDetailsStep.data.total_experience.years.toString() + " years" + "   " + professionalDetailsStep.data.total_experience.months.toString() + " months");
                        setRelevantExperience(professionalDetailsStep.data.relevant_experience.years.toString() + " years" + "   " + professionalDetailsStep.data.relevant_experience.months.toString() + " months");
                    } else {
                        setIsFresher(true);
                    }
                }
                let response = documentDetailsStep?.data;
                response && setDocumentDetails({
                    aadharDetails: {
                        name_as_per_Aadhar: response.aadhar_details?.aadharName || "",
                        aadhar_number: response.aadhar_details?.aadharNumber || undefined,
                        aadharFile: response.aadhar_details?.aadharFile || null,
                        aadharFileName: response.aadhar_details?.aadharFileName || "",
                    },
                    panDetails: {
                        name_as_per_PAN: response.pan_details?.panName || "",
                        pan_number: response.pan_details?.panNumber || undefined,
                        panFile: response.pan_details?.panFile || null,
                        panFileName: response?.pan_details?.panFileName || ""
                    },
                    passportDetails: {
                        passport_number: response.passport_details?.passportNumber || undefined,
                        issue_date: response.passport_details?.issueDate || undefined,
                        expiry_date: response.passport_details?.expiryDate || undefined,
                        passportFileName: response.passport_details?.passportFileName || "",
                    },
                    bankDetails: {
                        primaryAccount: {
                            account_holder_name: response.bank_details?.primaryAccount?.accountHolderName || "",
                            account_number: response.bank_details?.primaryAccount?.accountNumber || "",
                            IFSC_code: response.bank_details?.primaryAccount?.ifsc || "",
                            bank_name: response.bank_details?.primaryAccount?.bankName || "",
                            branch: response.bank_details?.primaryAccount?.branchName || "",
                            accountFileName: response.bank_details?.primaryAccount?.accountFileName || ""
                        },
                        secondaryAccount: {
                            account_holder_name: response.bank_details?.secondaryAccount?.accountHolderName || "",
                            account_number: response.bank_details?.secondaryAccount?.accountNumber || "",
                            IFSC_code: response.bank_details?.secondaryAccount?.ifsc || "",
                            bank_name: response.bank_details?.secondaryAccount?.bankName || "",
                            branch: response.bank_details?.secondaryAccount?.branchName || "",
                            accountFileName: response.bank_details?.secondaryAccount?.accountFileName || ""

                        },
                    },
                    uan: {
                        uan_number: response?.uan_number,
                    },
                    esi: {
                        esi_number: response?.esi_number,
                    },
                    dependentDetails: response?.dependent_details ?? [{
                        dependent_name: "",
                        Relationship: "",
                        Date_of_birth: "",
                        blood_group: "",
                        Occupation: "",
                    }].map((dep: any) => {
                        return {
                            dependent_name: dep?.dependentName || "",
                            Relationship: dep?.dependentRelationship || "",
                            Date_of_birth: dep?.dependentDob || "",
                            blood_group: dep?.dependentBloodGroup || "",
                            Occupation: dep?.dependentOccupation || "",
                        }
                    }),
                });
            }
            else {

            }
        })

    }, [])

    return (
        <HeaderLayout companyLogo={props?.companyDetails?.logo}>
            <div className="container-work">
                <TaskProgressBar empName={'Vikrant'} />
                {toast && (
                    <Toast
                        message={
                            toastMsg
                        }
                        status={"error"}
                    />
                )}
                <div className="professionaldetail documentdetail">
                    {/* <TaskProgressBar empName={'Vikrant'} /> */}
                    <h1>Declaration</h1>
                    <PersonalDetailDeclaration
                        basicDetails={basicDetail}
                        emergencyContactDetails={emergencyContactDetails}
                        addresses={Addresses}
                        profileImage={profilePic}
                    />
                    <EducationDeclaration educationDetails={educationDetails} />
                    <ProfessionalDeclaration workExperiences={WorkExperiences} hrDetails={hrDetails} totalExperience={totalExperience} relevantExperience={relevantExperience} fresher={isFresher} />
                    <DocumentDeclaration details={documentDetails} />
                    <Form.Check
                        type={"checkbox"}
                        id={`default-${"fresher"}`}
                        label={`I confirm that the information I've given is accurate and complete.`}
                        className={` ${checkboxError ? "fresher-checkbox-highlight" : ""} fresher-checkbox`}
                        checked={particularsVerified}
                        onChange={() => { setParticularsVerified(!particularsVerified); setCheckboxError(false) }}
                    />
                </div>
                <FooterLayout handleSave={handlePermissionModal} />
            </div>
            {/* <SubmitModal showModal={showModal} callBack={() => { }} /> */}
            <PermissionModal modalData={modalData} callBack={handleDeclaration} />
        </HeaderLayout>
    )
}

export default Declaration