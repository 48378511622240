import { Image } from "react-bootstrap";
import { Rows, formatLabel } from "../PersonalDetailDeclaration/utilities";


interface AadharDetails {
    name_as_per_Aadhar: string,
    aadhar_number?: number | undefined | string
}
interface PanDetails {
    name_as_per_PAN: string,
    pan_number?: number | undefined | string
}

interface UANandESI {
    uan_number?: number | undefined | string,
    esi_number?: number | undefined | string
}

interface PassportDetails {

    passport_number?: number | undefined | string
    issue_date?: number | undefined | string
    expiry_date?: number | undefined | string
}
interface AccountDetails {
    account_holder_name: number | undefined | string,
    account_number: number | undefined | string,
    IFSC_code: number | undefined | string,
    bank_name: number | undefined | string,
    branch: number | undefined | string,
}

interface DependentDetails {
    dependent_name: string,
    Relationship: string,
    Date_of_birth: string,
    blood_group: string,
    Occupation: string
}

export const SectionGeneratorforDocs = (basicDetails: AadharDetails | PanDetails | PassportDetails | AccountDetails | UANandESI | DependentDetails) => {
    const fieldsArray = Object.entries(basicDetails);
    const rows = [];
    for (let i = 0; i < fieldsArray.length; i += 2) {
        const firstField = fieldsArray[i];
        const secondField = fieldsArray[i + 1] || ["", ""]; // Handling odd number of fields

        if (!firstField[0].includes("File")) {
            rows.push({
                label1: formatLabel(firstField[0]),
                value1: firstField[1],
                label2: formatLabel(secondField[0]),
                value2: secondField[1]

            });
        }
    }

    return (
        <>
            {
                rows.map((row, index) => (
                    <Rows
                        key={index}
                        label1={row.label1}
                        values1={row.value1}
                        label2={row.label2}
                        values2={row.value2}
                    />
                ))
            }

        </>
    )
}

export const FileBadge = ({ fileName }: { fileName: string }) => {
    let format = fileName?.split(".");
    return (<div className="d-flex justify-content-between align-items-center">

        {fileName && <div className='file-uld w-50'>
            <div className='d-flex flex-row file-uld-img align-items-center '>
                <Image
                    src={`/images/drag_drop/${format[format.length - 1] === 'jpeg' || format[format.length - 1] === 'png' || format[format.length - 1] === 'svg' || format[format.length - 1] === 'jpg' ? 'jpg_icon' : 'pdf_icon'}.svg`}
                    alt="file"
                />
                <p className='ms-2 ellipsis' title={fileName}>{fileName}</p>
            </div>
        </div>}
    </div >)
}