import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from '../../components/select/Select';
import FileUpload from '../fileUpload/FileUpload';
import { EmpAddressDetails, HandleData } from '../../pages/PersonalDetails';
import { OptionObject } from '../../pages/PersonalDetails';

interface PropsObject {
    empAddress: EmpAddressDetails,
    handleData: HandleData,
    addressType: string,
    isDisable?: boolean,
    stateOptions: OptionObject[]
}

const AddressDetails = React.memo((props: PropsObject) => {
    const { empAddress, handleData, addressType, stateOptions } = props

    const handleFileChange = (file: File | null | string) => {
        if (typeof file !== "string") {
            handleData(addressType, "address_proof", file);
        }
        else {
            handleData(addressType, "proofErrorValue", file);
        }
    };

    const handleOnChange = (stateName: string, value: string | Date | null) => {
        if (stateName === "pin_code") {
            if (!isNaN(Number(value))) {
                handleData(addressType, stateName, value);
            }
            else {
                return;
            }
        }
        else {
            handleData(addressType, stateName, value);
        }
    };

    const blockInvalidChar = (e: React.KeyboardEvent<HTMLElement>) => {
        if (!/^\d+$/.test(e.key) && e.key !== "Backspace" && e.key !== "Tab" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "v" && e.key !== "V") {
            e.preventDefault()
        }
        return true;
    };

    return (
        <Form>
            <Form.Group className="int-grp">
                <Form.Label>Address line 1 <span>*</span></Form.Label>
                <Form.Control name="addressLineOne" className={`${empAddress?.lineOneError ? 'error-msg' : ''}`} type="text"
                    value={empAddress?.address_line_one} onChange={(e) => handleOnChange("address_line_one", e.target.value)} disabled={props?.isDisable} />
                {empAddress?.lineOneError && <Form.Text id="empMail">Please enter the address</Form.Text>}
            </Form.Group>
            <Form.Group className="int-grp">
                <Form.Label>Address line 2</Form.Label>
                <Form.Control name="addressLineTwo" type="text" value={empAddress?.address_line_two}
                    onChange={(e) => handleOnChange("address_line_two", e.target.value)} disabled={props?.isDisable} />
            </Form.Group>
            <Form.Group className="int-grp">
                <Form.Label>City <span>*</span></Form.Label>
                <Form.Control name="cityName" type="text" className={`${empAddress?.cityError ? 'error-msg' : ''}`} value={empAddress?.city}
                    onChange={(e) => handleOnChange("city", e.target.value)} disabled={props?.isDisable} />
                {empAddress?.cityError && <Form.Text id="empMail">Please enter the city name</Form.Text>}
            </Form.Group>
            <Form.Group className="int-grp gen-slt">
                <Form.Label>State <span>*</span></Form.Label>
                <Select
                    value={empAddress?.state}
                    options={stateOptions}
                    onChange={(val) => handleOnChange("state", val)}
                    placeholder="Select one"
                    width="100%"
                    margin="0.75rem 0 0"
                    className={`${empAddress?.stateError ? 'error-msg' : ''}`}
                    disabled={props?.isDisable}
                />
                {empAddress?.stateError && <Form.Text id="empMail">Please select the state</Form.Text>}
            </Form.Group>
            <Form.Group className="int-grp">
                <Form.Label>Pincode <span>*</span></Form.Label>
                <Form.Control name="pinCode" type="text" className={`${empAddress?.pinCodeError ? 'error-msg' : ''}`} value={empAddress?.pin_code}
                    onKeyDown={(e) => blockInvalidChar(e)} onChange={(e) => handleOnChange("pin_code", e.target.value)} disabled={props?.isDisable}
                    maxLength={6} />
                {empAddress?.pinCodeError && <Form.Text id="empMail">Please enter the pin code</Form.Text>}
            </Form.Group>
            <Form.Group className="int-grp">
                <Form.Label>Upload Permanent address proof <span>*</span></Form.Label>
                <FileUpload
                    fileValue={empAddress?.address_proof}
                    fileName={empAddress?.address_proof_name}
                    callBack={handleFileChange}
                    isDisable={props?.isDisable}
                    customClass={`${empAddress?.proofError ? 'error-msg' : ''}`}
                />
                {empAddress?.proofError && <Form.Text id="empMail">
                    {empAddress?.proofErrorValue === "" ? "Please upload the address proof" : empAddress?.proofErrorValue}
                </Form.Text>}
            </Form.Group>
        </Form>
    );
});

export default AddressDetails;