import React, { useState, useEffect } from 'react';
import { Image, Button, Container } from 'react-bootstrap';
import '../styles/headerLayout.scss';
import LogoutModal from './ModalPopups/LogoutModal';
import { getCookie } from '../utils/properties';
// import { EmpDetailsProvider } from './EmpDetailsContext';
import { UserDetails } from '../pages/Login';
import { useNavigate } from 'react-router';

interface layoutProps {
    companyLogo: string
    children: React.ReactNode;
}

export interface EmpDetails {
    "name": string,
    "userMail": string
}

const HeaderLayout = (props: layoutProps) => {
    const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
    const [empDetails, setEmpDetails] = useState<EmpDetails>({
        name: "",
        userMail: ""
    })
    const path = window.location.pathname.split('/')
    const Navigate = useNavigate();

    useEffect(() => {
        checkLoginStatus();
    }, [])

    const checkLoginStatus = () => {
        if (getCookie()) {
            const userDetails: UserDetails | undefined = getProfileInfo();
            if (userDetails && userDetails?.completed_steps) {
                const { personal_details, educational_details, professional_details, document_details, declaration } = userDetails?.completed_steps
                if (path[1] === 'personal' && userDetails?.form_status !== "submit") {
                    setEmpDetails({
                        name: userDetails?.name,
                        userMail: userDetails?.userMail
                    })
                }
                else if (path[1] === 'education' && personal_details && userDetails?.form_status !== "submit") {
                    setEmpDetails({
                        name: userDetails?.name,
                        userMail: userDetails?.userMail
                    })
                }
                else if (path[1] === 'professional' && personal_details && educational_details && userDetails?.form_status !== "submit") {
                    setEmpDetails({
                        name: userDetails?.name,
                        userMail: userDetails?.userMail
                    })
                }
                else if (path[1] === 'document' && personal_details && educational_details && professional_details && userDetails?.form_status !== "submit") {
                    setEmpDetails({
                        name: userDetails?.name,
                        userMail: userDetails?.userMail
                    })
                }
                else if (path[1] === 'declaration' && personal_details && educational_details && professional_details && document_details
                    && userDetails?.form_status !== "submit") {
                    setEmpDetails({
                        name: userDetails?.name,
                        userMail: userDetails?.userMail
                    })
                }
                else if (path[1] === "submit" && personal_details && educational_details && professional_details &&
                    document_details && declaration && userDetails?.form_status === "submit") {
                    setEmpDetails({
                        name: userDetails?.name,
                        userMail: userDetails?.userMail
                    })
                }
                else {
                    Navigate(-1);
                }
            }
        }
        else {
            window.location.href = "/";
        }
    }

    const getProfileInfo = () => {
        const userDetailsString = sessionStorage.getItem('userDetails');
        if (userDetailsString) {
            const userDetails: UserDetails = JSON.parse(userDetailsString);
            return userDetails;
        }
    }

    const childCallBack = () => {
        setShowLogoutModal(false)
    }

    return (
        <Container fluid className="w-100 m-0 p-0">
            <div className='header-container'>
                <div className='logo'>
                    <Image src={props?.companyLogo} alt='Logo' />
                </div>
                <div className='user-details d-flex justify-content-between align-items-center'>
                    <div className='name-avatar d-flex justify-content-center align-items-center'>
                        <p>{empDetails?.name?.charAt(0)}</p>
                    </div>
                    <p className='user-mail'>{empDetails?.userMail}</p>
                    <Button onClick={() => setShowLogoutModal(true)} ><Image src='/images/logout_icon.svg' />&nbsp; Logout</Button>
                </div>
            </div>
            {/* Wrap children with EmpDetailsProvider */}
            {/* <EmpDetailsProvider empDetails={empDetails}>
                {props.children}
            </EmpDetailsProvider> */}
            <>{props.children}</>
            <LogoutModal showModal={showLogoutModal} callBack={childCallBack} />
        </Container>
    );
};

export default HeaderLayout;