import { Image } from "react-bootstrap";
import { Rows } from "../PersonalDetailDeclaration/utilities";

export interface ExperienceDetail {
    job_title: string;
    company_Name: string;
    latest_experience?: boolean;
    "from-To"?: string,
    appointment_letter?: string;
    appraisal_letter?: string;
    releiving_letter?: string;
    pay_slip?: string;
    bank_statements?: string;

}
export interface HrDetail {
    hr_name: string;
    hr_email_address: string;
    hr_contact_number: string;
}

export const Fields = ({ label, values }: { label: string, values: string }) => {
    let format = values?.split(".");

    return (
        <>
            <p className="card-label">{label}</p>
            <div className="d-flex justify-content-between align-items-center">

                <div className='file-uld '>
                    <div className='d-flex flex-row file-uld-img align-items-center '>
                        <Image
                            src={`/images/drag_drop/${format[format.length - 1] === 'jpeg' || format[format.length - 1] === 'png' || format[format.length - 1] === 'svg' || format[format.length - 1] === 'jpg' ? 'jpg_icon' : 'pdf_icon'}.svg`}
                            alt="file"
                        />
                        <p className='ms-2 ellipsis' title={values}>{values}</p>
                    </div>
                </div>
            </div >
        </>
    );
};

// Function to format labels (replace _ with space and capitalize only the first word)
export const formatLabel = (label: string): string => {
    return label
        .replace(/_/g, ' ') // Replace underscores with spaces
        .replace(/^\w/, (char) => char.toUpperCase()); // Capitalize the first letter of the first word only
};

// Rows will display two fields in a row
export const FileRows = ({ label1, values1, label2, values2 }: { label1: string, values1: string, label2: string, values2: string }) => {
    return (
        <div className="d-flex justify-content-between align-items-center flex-row field-rows-bottom">
            {label1 !== "" && <div className="w-50 ">
                <Fields label={label1 === "Pay slip" ? "Payslips" : label1 === "Bank statement" ? "Bank statements" : label1} values={values1} />
            </div>}
            {label2 !== "" && <div className="w-50">
                <Fields label={label2 === "Pay slip" ? "Payslips" : label2 === "Bank statement" ? "Bank statements" : label2} values={values2} />
            </div>}
        </div>
    );
};

export const SectionGeneratorforhr = (basicDetails: HrDetail) => {
    const fieldsArray = Object.entries(basicDetails);
    const rows = [];
    for (let i = 0; i < fieldsArray.length; i += 2) {
        const firstField = fieldsArray[i];
        const secondField = fieldsArray[i + 1] || ["", ""]; // Handling odd number of fields
        rows.push({
            label1: formatLabel(firstField[0]),
            value1: firstField[1],
            label2: formatLabel(secondField[0]),
            value2: secondField[1]
        });
    }

    return (
        <>
            {
                rows.map((row, index) => (
                    <Rows
                        key={index}
                        label1={row.label1}
                        values1={row.value1}
                        label2={row.label2}
                        values2={row.value2}
                    />
                ))
            }

        </>
    )
}