import React, { useState, useEffect, useRef } from "react";
import { Image, Form, Button, Spinner } from "react-bootstrap";
import { commonGetService, commonPostservice, handleValidateEmail } from "../utils/properties";
import { useNavigate } from 'react-router';
import "../styles/login.scss";
import { CompanyProps } from "../App";
import Toast from "../components/Toaster/Toast";

interface Otp {
  "otp1": string,
  "otp2": string,
  "otp3": string,
  "otp4": string,
  "otp5": string
}

interface CompletedSteps {
  "personal_details": boolean,
  "educational_details": boolean,
  "professional_details": boolean,
  "document_details": boolean,
  "declaration": boolean
}

export interface UserDetails {
  "name": string,
  "userMail": string,
  "otp": number,
  "form_status": string,
  "completed_steps": CompletedSteps
}

const Login = (props: CompanyProps) => {
  const [emailId, setEmailId] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string>("");
  const [otpSent, setOtpSent] = useState<boolean>(false);
  const [otpData, setOtpData] = useState<Otp>({
    "otp1": "",
    "otp2": "",
    "otp3": "",
    "otp4": "",
    "otp5": ""
  })
  const [otpError, setOtpError] = useState<boolean>(false);
  const [otpInterval, setOtpInterval] = useState<number>(60);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const Navigate = useNavigate();
  const { name, logo } = props?.companyDetails

  const otpLength = 5;
  const otpRefOne = useRef<HTMLInputElement | null>(null)
  const otpRefTwo = useRef<HTMLInputElement | null>(null)
  const otpRefThree = useRef<HTMLInputElement | null>(null)
  const otpRefFour = useRef<HTMLInputElement | null>(null)
  const otpRefFive = useRef<HTMLInputElement | null>(null)
  const otpRefs = [otpRefOne, otpRefTwo, otpRefThree, otpRefFour, otpRefFive];


  useEffect(() => {
    const userDetailsString = sessionStorage.getItem('userDetails')
    if (userDetailsString) {
      const userDetails = JSON.parse(userDetailsString);
      setOtpSent(userDetails?.otpSent);
      setEmailId(userDetails?.userMail)
    }
    if (otpSent) {
      const otpTimeInterval = setInterval(() => {
        setOtpInterval((prev) => {
          if (prev > 0) {
            return prev - 1
          };
          clearInterval(otpTimeInterval);
          return 0;
        });
      }, 1000);
      return () => {
        clearInterval(otpTimeInterval);
      };
    }
  }, [otpSent]);


  const blockInvalidChar = (e: React.KeyboardEvent<HTMLElement>) => {
    if (!/^\d+$/.test(e.key) && e.key !== "Backspace" && e.key !== "Tab" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "v" && e.key !== "V") {
      e.preventDefault()
    }
    return true;
  };

  const handleOtpChange = (e: React.ChangeEvent<HTMLElement>, index: number) => {
    const target = e.target as HTMLInputElement;
    const value = target.value;
    if (!isNaN(Number(value))) {
      setOtpError(false);
      setOtpData({ ...otpData, [target.name]: value });

      // Move to the next input if available
      if (value && index < otpLength - 1) {
        otpRefs[index + 1].current?.focus();
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>, index: number) => {
    if (blockInvalidChar(e)) {
      const target = e.currentTarget as HTMLInputElement;

      // Move to the previous input if Backspace is pressed on an empty field
      if (e.key === "Backspace" && !target.value && index > 0) {
        otpRefs[index - 1].current?.focus();
      }
      if (e.key === 'Enter' && Object.entries(otpData)?.every(([key, value]) => { return value !== "" })) {
        handleVerifyOtp();
        e.preventDefault()
      }
    }
  }

  const handleValidate = (): boolean => {
    if (emailId.trim() === "") {
      setEmailError("Please enter the Email ID");
      return false;
    }
    else if (handleValidateEmail(emailId)) {
      setEmailError("Please enter the valid Email Id");
      return false;
    }

    return true;
  }

  const handleOtpCall = () => {
    let params = {
      "email": emailId.trim()
    }

    setIsLoading(true);
    commonPostservice(`onboard/send-otp`, params).then(res => {
      setIsLoading(false);
      if (res.status) {
        setTimeout(() => {
          otpRefOne?.current?.focus();
        }, 0)
        if (!otpSent) {
          let userDetails = {
            "userMail": emailId.trim(),
            "otpSent": true
          }
          setOtpSent(true);
          sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
        }
        else {
          alert('OTP sent successfully');
          return;
        }
      }
      else {
        alert(res.message);
      }
    })
  }

  const handleVerifyOtp = () => {
    if (!otpSent) {
      if (handleValidate()) {
        handleOtpCall();
      }
    }
    else {
      let otp = Object.values(otpData).join("");
      let params = {
        "email": emailId.trim(),
        "otp": Number(otp.trim())
      }

      setIsLoading(true);
      commonPostservice(`onboard/verify-otp`, params).then(res => {
        if (res.status) {
          let d = new Date();
          d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
          let expireTime = d.toUTCString();
          document.cookie = `X-XSRF-TOKEN = ${res?.token};expires=${expireTime};path=/`
          getProfileInfo(Number(otp.trim()));
        }
        else {
          setIsLoading(false);
          setOtpError(true);
        }
      })
    }
  }

  const getProfileInfo = (otp: number) => {
    commonGetService(`onboard/profileInfo`).then(res => {
      setIsLoading(false);
      if (res.status) {
        if (res?.data?.form_status !== "submit") {
          let userDetails: UserDetails = {
            "name": res?.data?.name,
            "userMail": res?.data?.email,
            "otp": otp,
            "form_status": res?.data?.form_status,
            "completed_steps": res?.data?.completed_steps
          }
          sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
          let navRoute = Object.entries(res?.data?.completed_steps)?.find(([key, value]) => {
            if (!value) {
              return key;
            }
          })
          // if (res?.data?.form_status !== "submit") {
            if (navRoute) {
              Navigate(navRoute[0] === "personal_details" ? '/personal' : navRoute[0] === "educational_details" ? '/education' :
                navRoute[0] === "professional_details" ? '/professional' : navRoute[0] === "document_details" ? '/document' : '/declaration', { replace: true });
            }
            else {
              Navigate('/personal', { replace: true });
            }
          // }
          // else {
          //   Navigate('/submit', { replace: true });
          // }
        }
        else {
          document.cookie = "X-XSRF-TOKEN = null;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          localStorage.clear();
          sessionStorage.clear();
          setIsSubmit(true);
          setEmailId("");
          setOtpSent(false);
          setOtpData({
            "otp1": "",
            "otp2": "",
            "otp3": "",
            "otp4": "",
            "otp5": ""
          });
          setTimeout(() => {
            setIsSubmit(false);
          }, 1500)
        }
      }
    })
  }

  const handleFocus = (ind: number) => {
    const input = otpRefs[ind]?.current;
    if (input) {
      setTimeout(() => {
        input.setSelectionRange(input.value.length, input.value.length);
      }, 0);
    }
  };

  return (
    <>
      <div className="login-container">
        <div className="d-flex justify-content-center align-items-center data-field">
          <div className="d-flex flex-column justify-content-center align-items-center login-tab">
            <Image src={logo} alt="logo" />
            <div className="d-flex flex-column login-content">
              <h4>{!otpSent ? "Login" : "OTP Verification"}</h4>
              {!otpSent ?
                <p>Please enter your email to receive a login OTP.</p> :
                <p>We've sent a login OTP to your email. Please <br /> enter the 6-digit code below.</p>
              }
              {!otpSent ?
                <Form>
                  <Form.Group className="int-grp">
                    <Form.Label>Email ID <span>*</span></Form.Label>
                    <Form.Control name="emailId" type="text" id='empMail' value={emailId} placeholder="Enter Your Email"
                      className={`${emailError !== "" ? 'error-msg' : ''}`} onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleVerifyOtp();
                          e.preventDefault()
                        }
                      }
                      }
                      onChange={(e) => { setEmailError(""); setEmailId(e.target.value) }}
                    />
                    {emailError !== "" && <Form.Text id="empMail">{emailError}</Form.Text>}
                  </Form.Group>
                </Form> :
                <div className="d-flex flex-column otp-container">
                  <div className="d-flex otp-input-grp">
                    {Object.entries(otpData)?.map(([key, val], index) => (
                      <Form.Control
                        key={index}
                        name={key}
                        type="text"
                        value={val}
                        maxLength={1}
                        ref={otpRefs[index]}
                        onChange={(e) => handleOtpChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        onClick={() => handleFocus(index)} // Add this for click events
                        onFocus={() => handleFocus(index)} // Retain focus handling
                        onPaste={(e) => {
                          e.preventDefault();
                          const pastedData = e.clipboardData.getData("text").slice(0, otpLength - index); // Limit pasted length
                          const newOtpData = JSON.parse(JSON.stringify(otpData));
                          pastedData.split("").forEach((char, idx) => {
                            if (index + idx < otpLength) {
                              newOtpData[`otp${index + idx + 1}`] = char;
                            }
                          });
                          setOtpData(newOtpData);
                          const nextFieldIndex = Math.min(index + pastedData.length, otpLength - 1);
                          otpRefs[nextFieldIndex]?.current?.focus();
                        }}
                        placeholder="-"
                        className={`${otpError ? 'err-box' : ''}`}
                        autoComplete="off"
                      />
                    ))}
                  </div>
                  {(otpSent && otpInterval > 0 && !otpError) ? <p className="time-interval">{otpInterval} Sec &nbsp;<a>Send OTP</a></p> :
                    (otpSent && otpInterval === 0 && !otpError) ? <p>Didn't receive the OTP? &nbsp;<a className={`${isLoading ? 'dis-otp' : ''}`} onClick={() => { if (!isLoading) { handleOtpCall() } }}>Send OTP</a></p> :
                      <p className="err-msg">Enter a valid OTP</p>
                  }
                </div>
              }
              <Button
                variant="dark"
                className="login-btn"
                onClick={() => handleVerifyOtp()}
                disabled={isLoading || (otpSent && Object.entries(otpData)?.some(([key, value]) => { return value === "" })) || otpError || emailId.trim() === ""}
              >
                {isLoading && !otpSent ? (
                  <Spinner as="span" animation="border" size="sm" />
                ) : (
                  otpSent ? "Verify OTP" : "Send OTP"
                )}
              </Button>
            </div>
          </div>
        </div>
        <p className="company-info">© 2024 {name}. All rights reserved.</p>
      </div >
      {isSubmit && <Toast message='Your details has already submitted' status='error' />}
    </>
  );
};

export default Login;
