import React from 'react'
import HeaderLayout from '../components/HeaderLayout'
import ProfessionalDetail from '../components/ProfessionalDetail/ProfessionalDetail'
import FooterLayout from '../components/FooterLayout'
import { CompanyProps } from '../App'


const ProfessionalDetails = (props: CompanyProps) => {
  return (
    <HeaderLayout companyLogo={props?.companyDetails?.logo}>
      <ProfessionalDetail />
    </HeaderLayout>
  )
}

export default ProfessionalDetails